
import { Routes } from '@angular/router';

import { AppShellComponent } from './shell/shell.component';
import { BootstrapComponent } from './bootstrap/bootstrap.component';
import { ExploreComponent } from './shell/explore/explore.component';
import { HelpComponent } from './shell/help/help.component';
import { HomeComponent } from './shell/home/home.component';
import { GuestCheckinComponent } from './shell/guest/guest.component';
import { WelcomeComponent } from './shell/welcome/welcome.component';

export const ROUTES: Routes = [
    { path: 'bootstrap', component: BootstrapComponent },
    { path: 'bootstrap/:level', component: BootstrapComponent },
    { path: '', component: AppShellComponent, children: [
        { path: '', component: ExploreComponent },
        { path: 'home', component: HomeComponent },
        { path: 'help', component: HelpComponent },
        { path: 'guest', component: GuestCheckinComponent },
        { path: 'welcome', component: WelcomeComponent },
        { path: 'explore/:search', component: ExploreComponent },
        { path: 'explore/:search/:level', component: ExploreComponent },
        { path: 'checkin', loadChildren: './shell/checkin/checkin.module#AppCheckinModule'}
    ] },
    { path: '**',      redirectTo: '' },
];
