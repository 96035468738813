
import { CommsService } from '@acaprojects/ngx-composer';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import * as moment from 'moment';

import { ILevel } from './buildings.service';
import { IBooking } from './bookings.service';
import { Utils } from '../../shared/utility.class';
import { BaseService } from './base.service';

export interface IRoom {
    id: string;
    name: string;
    email: string;
    level: ILevel;
    map_id: string;
    available?: boolean;
    bookable: boolean;
    in_use: string | boolean;
    raw_bookings: any[];
    bookings: IBooking[];
    searchable?: boolean;
    today?: IBooking[];
    zones?: string[];
    timeline?: any;
    rate?: number;
    support_url?: string;
    extras?: any[];
    capacity?: number;
    controllable?: boolean;
    book_type?: string;
    current?: IBooking;
    order?: number;
    next?: IBooking;
}

@Injectable({
    providedIn: 'root'
})
export class RoomsService extends BaseService {

    constructor(protected http: CommsService) {
        super();
        this.model.name = 'space';
        this.model.route = '/rooms';
        this.set('timelines', {});
        this.set('search', []);
        this.set('results', []);
        // Initialise state
        this.set('state', 'loading');
    }

    public init() {
        if (!this.parent || !this.parent.Buildings.current()) {
            return setTimeout(() => this.init(), 500);
        }
        this.load();
    }

    protected load() {
        this.query({}).then((rooms) => null, (e) => null);
    }

    /**
     * Get list of rooms
     * @param all All rooms for client
     * @param zone_ids List of zones that the rooms must be in
     */
    public list(all: boolean = false, zone_ids?: string | string[]) {
        const zones = zone_ids ? (zone_ids instanceof Array ? zone_ids || [] : [zone_ids]) : [];
        const list = this.get('list') || [];
        let rm_list = [];
        if (all) {
            rm_list = list;
        } else {
            const bld = this.parent && this.parent.Buildings.current() ? this.parent.Buildings.current() : null;
            const bld_list = list.filter((i) => i.level && i.level.bld_id === bld.id);
            rm_list = (bld ? bld_list : list);
        }
        return zones.length > 0 ? rm_list.filter((i) => {
            let cnt = 0;
            for (const id of zones) {
                if (i.zones.indexOf(id) >= 0) { cnt++; }
            }
            return cnt >= zones.length;
        }) : rm_list;
    }

    /**
     * Get list of available rooms
     * @param date Start time of availabilty block
     * @param duration Length of availabiliy block. Defaults to 60
     * @param id ID of room to check availability
     * @param bookable Only check bookable rooms. Defaults to true
     */
    public available(date: number, duration: number = 60, id?: string, bookable: boolean = true) {
        if (!this.parent || !this.parent.Buildings.current()) {
            return new Promise((rs, rj) =>
                setTimeout(() => this.available(date, duration, id, bookable).then((v) => rs(v), (e) => rj(e)), 500)
            );
        }
        this.set('state', 'loading');
        const start = moment(date);
        const end = moment(start).add(duration, 'm');
        const bld = this.parent.Buildings.current();
        const key = `availiable|${start.unix()}|${duration}${id ? '|' + id : ''}|${bld.id}`;
        if (!this.promises[key]) {
            this.promises[key] = new Promise((resolve, reject) => {
                const query = { bookable: true, available_from: start.unix(), available_to: end.unix(), zone_id: bld.id };
                const response = (list) => {
                    for (const i of list) {
                        if (!i.available) {
                            list.splice(list.indexOf(i), 1);
                        }
                    }
                    this.model.prevent_timeline_changes = false;
                    resolve(list);
                    this.set('state', 'idle');
                };
                const error = (err) => {
                    this.subjects.state.next('idle');
                    this.model.prevent_timeline_changes = false;
                    setTimeout(() => this.promises[key] = null, 5000);
                    reject(err);
                };
                if (id) {
                    this.model.prevent_timeline_changes = true;
                    this.show(id, query).then(response, error);
                } else {
                    this.query(query).then(response, error);
                }
            });
        }
        return this.promises[key];
    }

    /**
     * Check if room is available
     * @param id ID of room
     * @param date Start time of availabilty block
     * @param duration Length of availabiliy block. Defaults to 60
     */
    public isAvailable(id: string, date: number, duration: number = 60) {
        const start = moment(date);
        const key = `availiable|${id}|${start.unix()}|${duration}`;
        if (!this.promises[key]) {
            this.promises[key] = new Promise((resolve, reject) => {
                // Get availability of room
                this.available(date, duration, id, false).then((result) => {
                    const item = (result instanceof Array ? result[0] : result) || {};
                    // Check availability status of room
                    item.id === id && item.available ? resolve() : reject();
                    // Prevent new requests for 60 seconds
                    setTimeout(() => this.promises[key] = null, 60 * 1000);
                }, (err) => reject());
            });
        }
        return this.promises[key];
    }

    /**
     * Update room parameters
     * @param room Room data
     */
    public processRoom(room: IRoom) {
        if (room) {
            if (room.raw_bookings) {
                for (const bkn of (room.raw_bookings || [])) {
                    bkn.room_id = room.id;
                }
                room.bookings = this.processRoomBookings(room.raw_bookings);
                room.next = this.nextBooking(room.bookings);
                room.current = this.currentBooking(room.bookings);
                room.in_use = this.checkState(room.bookings);
                this.timelineBookings(room.id, room.bookings);
            }
        }
    }

    protected processItem(raw_item) {
        if (!raw_item.settings) { raw_item.settings = {}; }
        const settings = raw_item.settings;
        const lvl = this.parent.Buildings.getLevel(raw_item.zones) || {};
        const out: IRoom = {
            id: raw_item.id,
            name: raw_item.name,
            email: raw_item.email,
            level: lvl,
            map_id: raw_item.settings ? raw_item.settings.map_id : raw_item.map_id,
            available: raw_item.available || raw_item.settings.available,
            bookable: raw_item.bookable,
            raw_bookings: raw_item.settings.bookings || raw_item.bookings,
            order: raw_item.settings.room_order || raw_item.room_order || 999,
            bookings: [],
            extras: this.parent.Buildings.getExtras(raw_item.settings.extra_features || raw_item.extra_features),
            support_url: raw_item.settings.support_url || raw_item.support_url,
            capacity: raw_item.capacity,
            zones: raw_item.zones,
            book_type: raw_item.settings.book_type || lvl.book_type || ((lvl || {}).settings || {}).book_type || '',
            controllable: raw_item.settings.controllable || ((lvl || {}).settings || {}).controllable,
            rate: raw_item.settings.cost_hour || raw_item.cost_hour,
            in_use: false,
            next: null,
        };
        if (settings.bookable_by_request || ((lvl.settings || {}).bookable_by_request && settings.bookable_by_request !== false)) {
            out.book_type = 'Request';
        }
        // Add getter for timelines
        Object.defineProperty(out, 'timeline', {
            get: () => {
                const timeline = this.get('timelines') || {};
                return timeline[out.id] || {};
            }
        });
        Object.defineProperty(out, 'today', {
            get: () => {
                const timelines = this.get('timelines') || {};
                const timeline = timelines[out.id] || {};
                const now = moment();
                return timeline[now.format('DD MMM YYYY')] || [];
            }
        });
        this.processRoom(out);
        return out;
    }

    /**
     * Convert booking data into local format
     * @param bookings Array of room bookings
     */
    private processRoomBookings(bookings: any[]): IBooking[] {
        if (!bookings) { return []; }
        return this.parent.Bookings.processList(bookings) || [];
    }

    /**
     * Get the next upcoming booking
     * @param bookings Array of bookings
     */
    public nextBooking(bookings: any[]) {
        if (!bookings || bookings.length <= 0) {
            return null;
        }
        const now = moment();
        let booking: any = null;
        let booking_start: any = null;
        for (const event of bookings) {
            const start = moment(event.date);
            if (now.isSame(start, 'd') && start.isAfter(now) && (!booking_start || booking_start.isAfter(start))) {
                booking = event;
                booking_start = start;
            }
        }
        return booking;
    }

    /**
     * Get booking in progress
     * @param bookings Array of bookings
     */
    public currentBooking(bookings: any[]) {
        if (!bookings || bookings.length <= 0) {
            return null;
        }
        const now = moment();
        for (const event of bookings) {
            const start = moment(event.date);
            const end = moment(start).add(event.duration, 'm');
            if (now.isBetween(start, end, 'm', '[)')) {
                return event;
            }
        }
        return null;
    }

    /**
     * Get end time of the current booking
     * @param bookings Array of bookings
     */
    public checkState(bookings: any[]) {
        if (!bookings || bookings.length <= 0) {
            return false;
        }
        const now = moment();
        for (const event of bookings) {
            const start = moment(event.date);
            const end = moment(start).add(event.duration, 'm');
            if (start.isSameOrBefore(now) && end.isAfter(now)) {
                return end.format('h:mma');
            }
        }
        return false;
    }

    /**
     * Get bookings on the given date
     * @param bookings Array of bookings
     * @param date Date to check
     */
    public bookingsForDate(bookings: any[], date: any = moment()) {
        const list: any[] = [];
        for (const event of bookings) {
            const start = moment(event.date);
            if (start.isSame(date, 'd')) {
                list.push(event);
            }
        }
        return list;
    }

    /**
     * Create a list of bookings for each day
     * @param bookings Array of bookings
     */
    public timelineBookings(id: string, bookings: any[]) {
        if (this.model.prevent_timeline_changes) { return; }
        const timeline = this.get('timelines') || {};
        timeline[id] = {};
        const now = moment();
        // Add bookings to the timeline
        for (const bkn of bookings) {
            const date = moment(bkn.date).format('DD MMM YYYY');
            if (!timeline[id][date]) { timeline[id][date] = []; }
            // Remove matches
            for (const event of timeline[id][date]) {
                if (event.id === bkn.id) {
                    timeline[id][date].splice(timeline[id][date].indexOf(event), 1);
                    break;
                }
            }
            timeline[id][date].push(bkn);
        }
        // Sort timeline
        for (const i in timeline[id]) {
            if (timeline[id][i]) {
                timeline[id][i].sort((a, b) => a.date - b.date);
            }
        }
        this.subjects.timelines.next(timeline);
    }

    public replaceBooking(booking: IBooking) {
        const timeline = this.get('timelines') || {};
        if (booking.room && booking.room.id) {
            if (timeline[booking.room.id] && Object.keys(timeline[booking.room.id]).length > 0) {
                let found = false;
                for (const date in timeline[booking.room.id]) {
                    if (timeline[booking.room.id].hasOwnProperty(date)) {
                        const list = timeline[booking.room.id][date];
                        for (const bkn of list) {
                            if (bkn.id === booking.id) {
                                found = true;
                                list[list.indexOf(bkn)] = this.parent.Bookings.processItem(booking);
                                break;
                            }
                        }
                        list.sort((a, b) => a.date - b.date);
                        if (found) { break; }
                    }
                }
                if (!found) {
                    const bkn = this.parent.Bookings.processItem(booking);
                    const date = moment(bkn.date).format('DD MMM YYYY');
                    if (!timeline[booking.room.id][date]) { timeline[booking.room.id][date] = []; }
                    timeline[booking.room.id][date].push(bkn);
                }
            } else if (timeline[booking.room.id]) {
                this.timelineBookings(booking.room.id, [this.parent.Bookings.processItem(booking)]);
            }
        }
    }

    public removeFromTimeline(room_id: string, bkn_id: string, date: string = moment().format('DD MMM YYYY')) {
        const timeline = this.get('timelines') || {};
        if (timeline[room_id] && timeline[room_id][date]) {
            for (const bkn of timeline[room_id][date]) {
                if (bkn.id === bkn_id) {
                    timeline[room_id][date].splice(timeline[room_id][date].indexOf(bkn), 1);
                    break;
                }
            }
        }
        this.subjects.timelines.next(timeline);
    }

    public getRoom(id: string): IRoom {
        return this.item(id);
    }
}
