
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';

import { WidgetsModule } from '@acaprojects/ngx-widgets';
import { ComposerModule } from '@acaprojects/ngx-composer';
import { RouterModule } from '@angular/router';

import { ROUTES } from './explore.routes';
import { SharedComponentModule } from '../../shared/components/shared.module';

import { ExploreComponent } from './explore.component';
import { ExploreMapOverlayComponent } from './components/map-overlay/map-overlay.component';
import { ExploreSearchComponent } from './components/search/search.component';

const COMPONENTS: any = [
    ExploreComponent,
    ExploreMapOverlayComponent,
    ExploreSearchComponent
];

@NgModule({
    declarations: [
        ...COMPONENTS
    ],
    imports: [
        CommonModule,
        FormsModule,
        WidgetsModule,
        ComposerModule,
        // RouterModule.forChild(ROUTES),
        SharedComponentModule,
        ScrollingModule
    ],
    exports: [
        ...COMPONENTS
    ]
})
export class AppExploreModule { }
