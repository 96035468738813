
import { Component, Input, OnInit, ViewChild, TemplateRef, OnChanges } from '@angular/core';

import { AppService } from '../../../../services/app.service';
import { ILevel } from '../../../../services/data/buildings.service';
import { BaseComponent } from '../../../../shared/components/base.component';

@Component({
    selector: 'explore-map-overlay',
    templateUrl: './map-overlay.template.html',
    styleUrls: ['./map-overlay.styles.scss']
})
export class ExploreMapOverlayComponent extends BaseComponent implements OnInit, OnChanges {
    @Input() change: boolean;

    public model: any = {};
    public settings: any = {};

    @ViewChild('room_info', { static: false }) private room_info: TemplateRef<any>;
    @ViewChild('key_list', { static: false }) private key_list: TemplateRef<any>;

    constructor(private service: AppService) {
        super();
    }

    public ngOnInit() {
        super.ngOnInit();
        this.service.Buildings.listen((bld) => {
            if (bld) {
                this.model.bld = bld;
                this.model.system = bld.systems.desks;
                this.loadLevels();
            }
        });
        this.model.desks = {};
        this.model.show = {};
        this.model.template = {};
        this.model.options = [
            { id: 'level', name: 'Level Select', large: true, icon: { class: 'material-icons', value: 'layers' }, number: true, action: 'pickLevel' },
            { id: 'keys', name: 'Keys', icon: { class: 'material-icons', value: 'vpn_key' }, template: true },
            { id: 'settings', name: 'Map Settings', icon: { class: 'material-icons', value: 'settings' }, action: 'updateSettings' },
            { id: 'filters', name: 'Map Filters', icon: { class: 'material-icons', value: 'filter_list' }, action: 'showFilters' },
            { id: 'room', name: 'Room Info', icon: { class: 'material-icons', value: 'info' }, offset: 'middle', template: true }
        ];
        this.model.rooms = {};
        this.init();
        this.setTemplates();
    }

    public ngOnChanges(changes: any) {
        if (changes.change) {
            setTimeout(() => {
                this.model.rooms.list = this.service.Rooms.list();
                this.processRooms();
            }, 100);
        }
    }

    public setTemplates() {
        if (!this.room_info || !this.key_list) {
            return setTimeout(() => this.setTemplates(), 500);
        }
        this.model.template.room = this.room_info;
        this.model.template.keys = this.key_list;
        this.subs.obs.explore_lvl = this.service.listen('KIOSK.level', (value) => this.setLevelByID(value, false));
        this.subs.obs.explore_search = this.service.listen('EXPLORE.search', (value) => this.model.search = value);
    }

    public tapWindow(e: any) {
        this.model.keys.show = (this.model.keys ? false : this.model.keys);
    }

    public init() {
        if (!this.service.Settings.setup) {
            return setTimeout(() => this.init(), 500);
        }
        this.model.map_settings = this.service.Settings.get('app.map') || {};
        this.model.keys = {};
        this.model.keys.groups = this.model.map_settings.keys || {};
        this.model.keys.types = Object.keys(this.model.keys.groups);
        this.model.zones = {};
        this.model.zones.enabled = this.service.Settings.get('app.zones.enabled');
        this.model.zones.toggle = this.service.Settings.get('app.zones.toggle');
        this.model.settings = this.service.Settings.get('app.explore') || {};
        if (this.model.zones.enabled && this.model.zones.toggle) {
            this.model.keys.types = [this.model.zones.toggle];
        }
        if (this.model.keys) {
            this.model.keys.list = (this.model.keys.groups.room || []).concat(this.model.keys.groups.general || []);
        }
            // Remove keys option if there are no keys to display
        if ((!this.model.keys.types || this.model.keys.types.length <= 0) && (!this.model.keys.list || this.model.keys.list.length <= 0)) {
            for (const opt of this.model.options) {
                if (opt.id === 'keys') {
                    this.model.options.splice(this.model.options.indexOf(opt), 1);
                    break;
                }
            }
        }
        if (this.model.map_settings && this.model.map_settings.settings === false) {
            for (const opt of this.model.options) {
                if (opt.id === 'settings') {
                    this.model.options.splice(this.model.options.indexOf(opt), 1);
                    break;
                }
            }
        }
        if (this.model.map_settings && this.model.map_settings.info === false) {
            for (const opt of this.model.options) {
                if (opt.id === 'room') {
                    this.model.options.splice(this.model.options.indexOf(opt), 1);
                    break;
                }
            }
        }
        this.settings = { zoom: 0 };
        this.model.old = this.service.Settings.get('app.explore.old_overlay');
        this.model.hide = this.service.Settings.get('app.explore.hide_overlay_options');
        this.service.set('EXPLORE.settings', this.settings);
        this.subs.obs.rooms = this.service.Rooms.listen('room_list', () => {
            this.model.rooms.list = this.service.Rooms.list();
            this.processRooms();
        });
        this.initOld();
    }

    public initOld() {
        this.subs.obs.exp_show_dsks = this.service.listen('EXPLORE.show.desks', (state) => {
            this.model.show.desks = state;
        });
        this.subs.obs.exp_show_rms = this.service.listen('EXPLORE.show.rooms', (state) => {
            this.model.show.rooms = state;
        });
        this.subs.obs.exp_show_tms = this.service.listen('EXPLORE.show.teams', (state) => {
            this.model.show.teams = state;
        });
        this.subs.obs.exp_show_zns = this.service.listen('EXPLORE.show.zones', (state) => {
            this.model.zones.show = state;
        });
    }

    public loadLevels() {
        this.model.level = { list: [] };
        if (this.model.bld) {
            this.model.level.list = this.model.bld.levels;
            this.model.level.names = [];
            for (const lvl of this.model.level.list) {
                this.model.level.names.push(lvl.name);
            }
            this.setLevel(this.model.level.list[0]);
        }
    }

    public setLevelByID(id: string, emit: boolean = true) {
        if (id && this.model.level && this.model.level.list) {
            for (const lvl of this.model.level.list) {
                if (lvl.id === id) {
                    this.setLevel(lvl, emit);
                    return lvl;
                }
            }
        }
        return null;
    }

    public setLevel(lvl: ILevel, emit: boolean = true) {
        if (lvl && this.model.level && this.model.level.list && this.model.level.list.indexOf(lvl) >= 0) {
            const previous_lvl = this.model.level.active;
            this.model.level.active = lvl;
            this.model.level.index = this.model.level.list.indexOf(lvl);
            if (emit && (!previous_lvl || previous_lvl.id !== lvl.id)) {
                this.service.set('EXPLORE.level', lvl.id);
            }
            this.processRooms();
        }
    }

    public pickLevel() {
        if (this.model.level && this.model.level.list) {
            const lvl = this.model.level.active;
            this.service.Overlay.openModal('level-select', { data: { active: lvl ? lvl.id : '' } })
                .then((inst: any) => inst.subscribe((event) => {
                    if (event.type === 'Select') {
                        this.setLevelByID(event.data.active);
                    }
                    this.model.show_menu = false;
                    event.close();
                }));
        }
    }

    public processRooms() {
        if (this.model.rooms && this.model.rooms.list) {
            this.model.rooms.count = 0;
            this.model.rooms.free = 0;
            for (const rm of this.model.rooms.list) {
                if (rm.level && rm.level.id === this.model.level.active.id && rm.bookable) {
                    this.model.rooms.count++;
                    if (!rm.in_use) {
                        this.model.rooms.free++;
                    }
                }
            }
            this.model.rooms.percent = (this.model.rooms.free / (this.model.rooms.count || 1)) * 100;
            this.service.set('KIOSK.rooms.free', this.model.rooms.free);
            this.service.set('KIOSK.rooms.count', this.model.rooms.count);
            this.service.set('KIOSK.rooms.percent', this.model.rooms.percent);
        }
        if (this.model.desks) {
            this.model.desks.percent = ((this.model.desks.free || 0) / (this.model.desks.count || 1)) * 100;
            this.service.set('KIOSK.desks.free', this.model.desks.free);
            this.service.set('KIOSK.desks.count', this.model.desks.count);
            this.service.set('KIOSK.desks.percent', this.model.desks.percent);
        }
    }

    public updateSettings() {
        this.service.Overlay.openModal('map-filter', {})
            .then((inst: any) => inst.subscribe((event) => {
                event.close();
                this.model.show_menu = false;
                this.model.show_filters = false;
            }));
    }

    public showFilters() {
        this.model.show_filters = !this.model.show_filters;
    }

    public updateState() {
        this.service.set('EXPLORE.show.desks', this.model.show.desks);
        this.service.set('EXPLORE.show.rooms', this.model.show.rooms);
        this.service.set('EXPLORE.show.teams', this.model.show.teams);
        if (this.model.zones) {
            this.service.set('EXPLORE.show.zones', this.model.zones.show);
        }
        this.service.set('EXPLORE.filters', !this.service.get('EXPLORE.filters'));
    }

    public action(item: any) {
        if (item.action && this[item.action] instanceof Function) {
            this[item.action]();
        }
    }

    public toggle(name: string, mobile: boolean = false) {
        const key = `${name}${mobile ? '_mobile' : ''}`;
        setTimeout(() => {
            if (this.model[name]) {
                this.model[name].show = !this.model[name].show;
            } else {
                this.model.show[key] = !this.model.show[key];
            }
        }, 300);
    }

    public zoom(value) {
        const zoom = Math.max(100, ((this.settings.zoom || 100)) * value);
        if (!this.settings) { this.settings = {}; }
        this.settings.zoom = zoom;
        this.postSettings();
    }

    public reset() {
        if (!this.settings) { this.settings = {}; }
        this.settings.zoom = 100;
        this.settings.center = { x: .5, y: .5 };
        this.postSettings();
    }

    public applyFilters() {
        const zones = this.model.level.active.map.features;
        const hide: string[] = [];
        for (const z of zones) {
            if (!z.active) { hide.push(z.id); }
        }
        this.service.set('EXPLORE.map.hide', hide.length === zones.length ? [] : hide);
        this.model.show_filters = false;
    }

    public postSettings() {
        this.settings = JSON.parse(JSON.stringify(this.settings));
        this.service.set('EXPLORE.settings', this.settings);
    }

}
