import { OverlayContentComponent } from '@acaprojects/ngx-widgets';
import { Component } from '@angular/core';

import * as moment from 'moment';

@Component({
    selector: 'map-filter-modal',
    templateUrl: './map-filter-modal.template.html',
    styleUrls: ['./map-filter-modal.styles.scss'],
})
export class MapFilterModalComponent extends OverlayContentComponent {
    public model: any = {};

    public init() {
        this.model.show = {};
        this.service.listen('EXPLORE.show.desks', (state) => {
            this.model.show.desks = state;
        });
        this.service.listen('EXPLORE.show.rooms', (state) => {
            this.model.show.rooms = state;
        });
        this.service.listen('EXPLORE.show.teams', (state) => {
            this.model.show.teams = state;
        });
        this.service.listen('EXPLORE.show.zones', (state) => {
            this.model.show.zones = state;
        });
    }

    public updateState() {
        this.service.set('EXPLORE.show.desks', this.model.show.desks);
        this.service.set('EXPLORE.show.rooms', this.model.show.rooms);
        this.service.set('EXPLORE.show.teams', this.model.show.teams);
        this.service.set('EXPLORE.show.zones', this.model.show.zones);
        this.service.set('EXPLORE.filters', !this.service.get('EXPLORE.filters'));
    }

    public close() {
        setTimeout(() => this.fn.close(), 300);
    }

    public event(name: string) {
        this.fn.event(name);
    }

}
