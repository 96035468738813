
import { Component, Input, OnInit } from '@angular/core';

import { AppService } from '../../../services/app.service';
import { BaseComponent } from '../../../shared/components/base.component';

// declare let sha256: any;
import * as moment from 'moment';
import * as hash from 'hash.js';


@Component({
    selector: 'guest-visitor-pass',
    templateUrl: './visitor-pass.template.html',
    styleUrls: ['./visitor-pass.styles.scss']
})
export class GuestVisitorPassComponent extends BaseComponent implements OnInit {
    @Input() public form: any = {};

    public model: any = {};

    constructor(private service: AppService) {
        super();
    }

    public ngOnInit() {
        super.ngOnInit();
        this.init();
    }

    public init() {
        super.ngOnInit();
        if (!this.service.Settings.setup) {
            return setTimeout(() => this.init(), 500);
        }
        this.model.logo = this.service.Settings.get('app.logo');
        setTimeout(() => {
            this.model.show = true;
            setTimeout(() => this.service.navigate(''), 30 * 1000);
        }, 5 * 1000);
        const secret = this.service.get('wifi_secret');
        if (secret) {
            this.model.secret = hash.sha512().update(`${moment().format('YYYYMMDD')}${secret}`).digest('hex');
            if (this.model.secret && this.model.secret.length > 5) {
                const index = Math.max(0, Math.floor(Math.random() * this.model.secret.length - 5));
                this.model.code = this.model.secret.substr(index, 5);
                this.service.info(`Your wifi access code is: <br><strong>${this.model.code.toUpperCase()}</strong>`, 'OK', () => null);
            }
        }
    }

}
