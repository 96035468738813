import { Injectable } from '@angular/core';
import { CommsService } from '@acaprojects/ngx-composer';
import { BehaviorSubject } from 'rxjs';

import { BaseService } from './base.service';
import { IUser } from './users.service';

@Injectable({
    providedIn: 'root'
})
export class ContactsService extends BaseService {

    constructor(protected http: CommsService) {
        super();
        this.model.name = 'contact';
        this.model.route = '/contacts';
        this.subjects.list = new BehaviorSubject<IUser[]>([]);
        this.observers.list = this.subjects.list.asObservable();
    }

    public load() {
        if (this.parent && this.parent.Settings.get('app.contacts.enabled')) {
            this.query().then(() => null, () => null);
        }
    }

    /**
     * Convert user data to local format
     * @param user User data
     */
    protected processItem(user: any) {
        if (!user) { return null; }
        const u_org = user.organisation;
        const org = {
            id: typeof u_org !== 'string' && u_org ? u_org.id : user.organisation_id,
            name: typeof u_org !== 'string' && u_org ? u_org.name : user.organisation_name || user.organisation,
        };
        const member: IUser = {
            id: user.id || user.email,
            win_id: user.email,
            name: user.name || `${user.first_name} ${user.last_name}`,
            first_name: user.first_name,
            last_name: user.last_name,
            type: user.title ? (user.title.toLowerCase() === 'partner' ? 'partner' : 'internal') : 'external',
            image: null,
            email: user.email,
            phone: user.phone || user.mobile,
            b_unit: user.department,
            organisation_id: org.id,
            organisation_name: org.name,
            staff_code: user.staff_code
        };
        if (member.id) {
            member.image = user.image || `${this.parent.endpoint}/assets/users/${member.id}.png`;
        }
        return member;
    }

}
