
import * as moment from 'moment';

export class FormChecks {

    public static checkDate(d: number) {
        const now = moment();
        const date = moment(d);
        if (date.isBefore(now, 'd')) {
            return { message: 'Booking needs to be made in the future' };
        }
        return null;
    }

    public static checkTime(d: number) {
        const now = moment();
        const date = moment(d);
        if (date.isBefore(now, 'm')) {
            return { message: 'Booking time needs to be made in the future' };
        }
        return null;
    }

    public static checkDuration(len: number, min: number = 15, max: number = 480) {
        if (len < (min || 15)) {
            return { message: `Minimum booking duration is ${min} minute${min === 1 ? 's' : ''}` };
        } else if (len > (max || 480)) {
            return { message: `Maximum booking duration is ${max} minute${max === 1 ? 's' : ''}` };
        }
        return null;
    }

    public static checkAttendees(capacity: number, attendees: any[], min: number = 0) {
        if (attendees) {
            if (capacity > 0) {
                const peeps = attendees;
                const warn = capacity && capacity <= peeps.length;
                if (warn) {
                    const error = {
                        warn,
                        message: 'There are more attendess than the capacity of the space',
                    };
                    return error;
                }
            }
            if (attendees.length < min) {
                return { message: `Minimum of ${min} attendee${min > 1 ? 's' : ''} other than you is required` };
            }
        } else if (min > 0) {
            return { message: `Minimum of ${min} attendee${min > 1 ? 's' : ''} other than you is required` };
        }
    }

    constructor() {
        throw new Error('This is a static class');
    }
}
