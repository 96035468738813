
import { Component, OnInit, OnDestroy, Input, EventEmitter, Output, OnChanges } from '@angular/core';

import { AppService } from '../../../services/app.service';

import * as moment from 'moment';
import { BaseComponent } from '../../../shared/components/base.component';

@Component({
    selector: 'guest-visitor-search',
    templateUrl: './visitor-search.template.html',
    styleUrls: ['./visitor-search.styles.scss']
})
export class  GuestVisitorSearchComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
    @Input() public form: any = {};
    @Output() public formChange = new EventEmitter();
    @Output() public event = new EventEmitter();

    public model: any = { form: {} };

    constructor(private service: AppService) {
        super();
    }

    public ngOnInit() {
        super.ngOnInit();
        if (!this.model.form) {
            this.model.form = {};
        }
        this.service.Visitors.listen('timeline', (list) => {
            const now = moment().add(-10, 'm');
            const today = list[now.format('YYYY/MM/DD')] || [];
            const user_list = [];
            for (const item of today) {
                const arrival = moment(item.date);
                if (arrival.isSameOrAfter(now, 'm')) {
                    for (const user of item.visitors) {
                        let found = false;
                        for (const u of user_list) {
                            if (u.id === user.id || u.email.toLowerCase() === user.email.toLowerCase()) {
                                found = true;
                                if (moment(u.date).isAfter(arrival, 'm')) {
                                    u.date = arrival.valueOf();
                                    u.host = item.host;
                                    u.arrival = item.arrival;
                                    u.id = item.id;
                                }
                            }
                        }
                        if (!found) {
                            user.date = arrival.valueOf();
                            user.arrival = item.arrival;
                            user.host = item.host;
                            user.id = item.id;
                            user_list.push(user);
                        }
                    }
                }
            }
            user_list.sort((a, b) => a.date - b.date || (a.name || '').localeCompare(b.name || ''));
            this.model.visitors = user_list;
            this.filter();
        });
    }

    public ngOnChanges(changes: any) {
        if (changes.form && this.form) {
            this.model.form = JSON.parse(JSON.stringify(this.form));
        }
    }

    public ngOnDestroy() {

    }

    public init() {
        if (!this.service.Settings.setup) {
            return setTimeout(() => this.init(), 500);
        }
        this.model.background = this.service.Settings.get('app.guest.bg');
    }

    public postForm() {
        this.formChange.emit(JSON.parse(JSON.stringify(this.model.form)));
    }

    public edit(item) {
        this.service.Overlay.openModal('visitor', { data: { visitor: item } }, (e) => {
            if (e.type === 'Accept') {
                this.model.form.visitor = e.data.form;
                this.postForm();
                this.event.emit('post');
            }
            e.close();
        });
    }

    public select(item) {
        this.service.confirm({
            title: `Confirm checkin`,
            message: `Checkin as ${item.name}, meeting ${item.host.name} at ${item.arrival}`,
            icon: 'done',
            accept: 'Ok',
            cancel: true,
        }, (e) => {
            if (e.type === 'Accept') {
                this.model.form.visitor = item;
                this.postForm();
                this.event.emit('post');
            }
            e.close();
        });
    }

    public filter() {
        this.model.filtered_items = [];
        if (!this.model.search) {
            this.model.filtered_items = this.model.visitors;
        } else {
            const search = this.model.search.toLowerCase();
            for (const user of this.model.visitors) {
                if (user.email.toLowerCase().indexOf(search) >= 0 || user.name.toLowerCase().indexOf(search) >= 0 ||
                    (user.host && user.host.name.toLowerCase().indexOf(search) >= 0)) {
                    this.model.filtered_items.push(user);
                }
            }
        }
    }

    public newVisitor() {
        this.service.Overlay.openModal('new-visitor', { data: {} }, (event) => {
            if (event.type === 'Accept') {
                const details = event.data.form;
                if (details.first_name && details.last_name) {
                    details.name = `${details.first_name} ${details.last_name}`;
                } else {
                    details.name = details.email.split('@')[0];
                }
                const date = moment().seconds(0);
                date.minutes(Math.ceil(date.minutes() / 5) * 5);
                this.service.Visitors.add({
                    name: 'kiosk-adhoc',
                    date: date.valueOf(),
                    host: details.host,
                    visitors: [{
                        email: details.email,
                        name: details.name,
                        first_name: details.first_name,
                        last_name: details.last_name
                    }]
                }).then((item) => {
                    details.group = { id: item.id, name: 'kiosk-adhoc' };
                    details.id = item.id;
                    this.model.form.visitor = details;
                    this.postForm();
                    this.event.emit('post');
                }, (err) => this.service.error('Failed to create visitor'));
            }
            event.close();
        });
    }

}
