
import { GuestCheckinComponent } from './guest.component';
import { GuestVisitorSearchComponent } from './visitor-search/visitor-search.component';
import { GuestHostSearchComponent } from './host-search/host-search.component';
import { GuestVisitorPassComponent } from './visitor-pass/visitor-pass.component';

export const GUEST_CHECKIN_COMPONENTS: any[] = [
    GuestCheckinComponent,
    GuestVisitorSearchComponent,
    GuestHostSearchComponent,
    GuestVisitorPassComponent
];
