
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { WidgetsModule } from '@acaprojects/ngx-widgets';

import { BaseComponent } from './base.component';
import { BookingFormComponent } from './booking-form/booking-form.component';
import { RoomInfoComponent } from './room-info/room-info.component';
import { SearchbarComponent } from './searchbar/searchbar.component';
import { USER_SEARCH_COMPONENTS } from './user-search';
import { UserTagComponent } from './user-tag/user-tag.component';
import { AvatarComponent } from './avatar/avatar.component';
import { DeskInfoComponent } from './desk-info/desk-info.component';

const COMPONENTS: any[] = [
    BaseComponent,
    BookingFormComponent,
    RoomInfoComponent,
    SearchbarComponent,
    UserTagComponent,
    AvatarComponent,
    DeskInfoComponent,
    ...USER_SEARCH_COMPONENTS
];

@NgModule({
    declarations: [
        ...COMPONENTS
    ],
    imports: [
        CommonModule,
        FormsModule,
        WidgetsModule
    ],
    exports: [
        ...COMPONENTS
    ],
    entryComponents: [
        RoomInfoComponent,
        DeskInfoComponent
    ]
})
export class SharedComponentModule { }
