
import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppService } from '../../services/app.service';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '../../shared/components/base.component';

@Component({
    selector: 'app-guest-checkin',
    templateUrl: './guest.template.html',
    styleUrls: ['./guest.styles.scss']
})
export class  GuestCheckinComponent extends BaseComponent implements OnInit {

    public model: any = {};

    constructor(private service: AppService, private route: ActivatedRoute) {
        super();
    }

    public ngOnInit() {
        super.ngOnInit();
        this.model.form = {};
        this.init();
    }

    public init() {
        if (!this.service.Settings.setup) {
            return setTimeout(() => this.init(), 500);
        }
        this.model.background = this.service.Settings.get('app.guest.bg');
    }

    public event(e: any) {
        this.model.page = '';
        if (e === 'Search' || e === 'search') {
            this.model.page = 'search';
        } else if (e.type === 'Select' || e.type === 'select') {
            if (!this.model.form) { this.model.form = {}; }
            this.model.form.host = e.item && e.item.host ? e.item.host.name : '';
            this.model.form.host_avatar = e.item && e.item.host ? e.item.host.avatar : '';
            this.model.form.visitor = e.item;
            this.model.form = JSON.parse(JSON.stringify(this.model.form));
        } else if (e === 'Post' || e === 'post') {
            this.model.page = 'loading';
            this.service.Visitors.checkin(this.model.form.visitor.id, {
                attendee_email: this.model.form.visitor.email,
                room_id: this.model.form.visitor.room_id,
                host_email: this.model.form.visitor.host.email,
                icaluid: this.model.form.visitor.icaluid
            }).then(() => {
                this.model.page = 'pass';
            }, (err) => {
                this.service.error(`Error checking in user, please check that your email is correct`);
                this.model.page = '';
            });
        }
    }

    public goto(id: string) {
        if (id) {
            this.service.navigate([this.model.level || 'no-lvl', id]);
        }
    }
}
