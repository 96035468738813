
import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';

import { FormChecks } from './form-checks.class';
import { AppService } from '../../../services/app.service';

import * as moment from 'moment';
import { BaseComponent } from '../base.component';

export interface IBookingFormSettings {
    edit?: boolean;
    week?: boolean;
    recurrence?: boolean;
    attendees?: number;
    fixed?: string[];
    hide_errors?: boolean;
}

@Component({
    selector: 'booking-form',
    templateUrl: './booking-form.template.html',
    styleUrls: ['./booking-form.styles.scss']
})
export class BookingFormComponent extends BaseComponent implements OnInit, OnChanges {
    @Input() public form: any = {};
    @Input() public check: boolean;
    @Input() public valid: boolean;
    @Input() public settings: IBookingFormSettings = {};
    @Input() public error: any = {};
    @Output() public errorChange = new EventEmitter();
    @Output() public formChange = new EventEmitter();
    @Output() public validChange = new EventEmitter();
    @Output() public event = new EventEmitter();

    public model: any = { form: {}, error: {}, display: {} };

    public events = {
        room: () => this.event.emit('Space'),
        attendees: () => this.event.emit('Attendees'),
        date: () => this.selectDate(),
        time: () => this.selectTime(true),
        host: () => this.setHost(),
        duration: () => this.selectTime(),
        catering: () => this.selectCatering()
    };

    private timers: any = {};
    private checklist: any = {};

    constructor(private service: AppService) {
        super();
        this.model.fields = [];
    }

    public ngOnInit() {
        super.ngOnInit();
        this.model.focus = {};
        this.model.form_checked = false;
        if (!this.model.form || !this.model.form.room) {
            this.loadForm();
        }
        if (!this.model.form) { this.model.form = {}; }
        if (!this.model.show) { this.model.show = {}; }
        if (!this.model.enable) { this.model.enable = {}; }
        if (!this.model.form.date) {
            const now = moment();
            now.minutes(Math.ceil(now.minutes() / 5) * 5).seconds(0).milliseconds(0);
            this.model.form.date = now.valueOf();
        } else {
            const date = moment(this.model.form.date);
            if (date.isBefore(moment(), 'm')) {
                const now = moment();
                now.minutes(Math.ceil(now.minutes() / 5) * 5).seconds(0).milliseconds(0);
                this.model.form.date = now.valueOf();
            }
        }
        if (!this.model.form.duration) {
            this.model.form.duration = this.service.Settings.get('app.booking.default_length') || 60;
        }
        if (!this.model.form.recurr) { this.model.form.recurr = 0; }
        this.model.show.recurrence = true;
        this.subs.obs.loan_items = this.service.Buildings.listen((bld) => {
            if (bld) {
                this.model.loan_items = JSON.parse(JSON.stringify(bld.loan_items));
                if (this.model.form.loan_items) {
                    for (const i of this.model.loan_items) {
                        i.active = this.model.form.loan_items.indexOf(i.id) >= 0;
                    }
                }
            }
        });
        this.updateSettings();
        this.generateWeek();
        this.init();
    }

    public init() {
        if (!this.service.ready()) {
            return setTimeout(() => this.init(), 500);
        }
        this.model.settings = this.service.Settings.get('app.booking') || {};
        this.model.field_list = this.service.Settings.get('app.booking.fields') || [];
        this.model.fields = this.model.field_list;
        this.model.simple = false;
        if (this.model.form && this.model.form.room && this.model.form.room.capacity === 1) {
            this.model.fields = [];
            this.model.simple = true;
            for (const f of this.model.field_list) {
                if (f.simple) {
                    this.model.fields.push(f);
                }
            }
        }
        this.timeout('defaults', () => {
            for (const f of this.model.fields) {
                if (f.default) {
                    this.model.form[f.id] = f.default;
                }
            }
        });
        for (const f of this.model.fields) {
            if (f.type === 'multi') {
                this.model.form[f.id] = this.model.form[f.id] || {};
            }
        }
        this.model.enable.recurrence = this.model.settings.recurrence && (!this.settings || this.settings.recurrence !== false);
        this.model.error = {};
        this.checklist = {
            attendees: (list: any[]) => FormChecks.checkAttendees(
                this.model.form.room ? this.model.form.room.capacity : 0, list,
                this.settings.attendees || (this.model.settings ? this.model.settings.min_attendees : 1)
            ),
            date: (date: number) => FormChecks.checkDate(date),
            duration: (duration: number) => FormChecks.checkTime(this.model.form.date) || FormChecks.checkDuration(duration),
        };
        this.storeForm();
        this.updateDisplay();
        setTimeout(() => this.model.initialised = true, 200);
    }

    public ngOnChanges(changes: any) {
        if (changes.form) {
            setTimeout(() => {
                this.model.simple = false;
                if (this.form.room) {
                    this.form.room = {
                        id: this.form.room.id || '',
                        name: this.form.room.name || '',
                        capacity: this.form.room.capacity || 0,
                        level: this.form.room.level || {},
                        email: this.form.room.email || '',
                        book_type: this.form.room.book_type || ''
                    };
                }
                this.model.form = JSON.parse(JSON.stringify(this.form || {}));
                if (this.model.loan_items && this.model.form.loan_items) {
                    for (const i of this.model.loan_items) {
                        i.active = this.model.form.loan_items.indexOf(i.id) >= 0;
                    }
                }
                if (this.model.form && this.model.form.room && this.model.form.room.capacity === 1) {
                    this.model.simple = true;
                    this.model.fields = [];
                    for (const f of this.model.field_list) {
                        if (f.simple) {
                            this.model.fields.push(f);
                        }
                    }
                }
                this.checkFields();
                this.updateDisplay(false);
                this.storeForm(false);
            }, 50);
        }
        if (changes.check) {
            setTimeout(() => this.checkFields(), 50);
        }
        if (changes.error && this.error) {
            setTimeout(() => this.model.error = this.error, 100);
        }
        if (changes.settings) {
            setTimeout(() => this.updateSettings(), 110);
        }
    }

    public generateWeek() {
        this.model.week = [];
        if (!this.model.form.date) {
            const today = moment().hours(0).minutes(0).seconds(0).milliseconds(1);
            today.minutes(Math.ceil(today.minutes() / 5) * 5).seconds(0).milliseconds(0);
            this.model.form.date = today.valueOf();
        }
        const selected = moment(this.model.form.date);
        const date = moment(this.model.form.date);
        const now = moment();
        for (let i = 0; i < 7; i++) {
            date.day(i);
            this.model.week.push({
                day: date.format('ddd'),
                date: date.date(),
                value: date.valueOf(),
                past: date.isBefore(now, 'd'),
                active: date.isSame(selected, 'd'),
                weekend: i === 0 || i === 6
            });
        }
    }

    public selectDay(day: any) {
        const now = moment();
        now.minutes(Math.ceil(now.minutes() / 5) * 5).seconds(0).milliseconds(0);
        const current = moment(this.model.form.date);
        const new_date = moment(day.value);
        new_date.hours(current.hours()).minutes(current.minutes());
        if (new_date.isBefore(now)) {
            new_date.hours(now.hours()).minutes(now.minutes());
        }
        this.model.form.date = new_date.valueOf();
        this.model.error.date = null;
        this.model.old_form = true;
        this.generateWeek();
        this.storeForm();
        this.updateDisplay();
    }

    public selectDate(name: string = 'date') {
        const now = moment();
        now.minutes(Math.ceil(now.minutes() / 5) * 5).seconds(0).milliseconds(0);
        if (!this.model.form[name]) {
            this.model.form[name] = now.valueOf();
        }
        const date = moment(this.model.form[name]);
        this.service.Overlay.openModal('calendar', { data: { date: this.model.form[name] } })
            .then((inst: any) => inst.subscribe((event) => {
                if (event.type === 'Accept') {
                    if (event.data.form && event.data.form.date) {
                        const new_date = moment(event.data.form.date).hours(date.hours()).minutes(date.minutes())
                            .seconds(0).milliseconds(0);
                        this.model.form[name] = new_date.valueOf();
                        this.model.error[name] = null;
                        this.model.old_form = true;
                    }
                    this.generateWeek();
                    this.storeForm();
                    this.updateDisplay();
                }
                event.close();
            }));
    }

    public selectTime(simple: boolean = false) {
        if (!this.model.form.date) {
            const now = moment();
            now.minutes(Math.ceil(now.minutes() / 5) * 5).seconds(0).milliseconds(0);
            this.model.form.date = now.valueOf();
        }
        if (!this.model.form.duration) {
            this.model.form.duration = this.service.Settings.get('app.booking.default_length') || 60;
        }
        const bkn_date = moment(this.model.form.date);
        const bkn_end = moment(bkn_date).add(this.model.form.duration, 'm');
        const start_time = bkn_date.format('HH:mm');
        const end_time = bkn_end.format('HH:mm');
        this.service.Overlay.openModal('time-period', { data: { start: start_time, end: end_time, simple } })
            .then((inst: any) => inst.subscribe((event) => {
                if (event.type === 'Accept') {
                    const form = event.data.form;
                    const new_start = form.start.split(':');
                    const new_end = form.end.split(':');
                    const start = moment(bkn_date);
                    start.hours(new_start[0]).minutes(new_start[1]);
                    if (!simple) {
                        const end = moment(bkn_date);
                        end.hours(new_end[0]).minutes(new_end[1]);
                        this.model.form.duration = Math.floor(Math.abs(moment.duration(start.diff(end)).asMinutes()));
                        this.model.error.duration = null;
                    }
                    this.model.form.date = start.valueOf();
                    this.model.old_form = true;
                    this.storeForm();
                    this.updateDisplay();
                }
                event.close();
            }));
    }

    public selectCatering() {
        this.service.Overlay.openModal('catering', { data: { catering: this.model.form.catering } }, (event) => {
            if (event.type === 'Accept') {
                this.model.form.catering = event.data.cart;
                this.updateDisplay();
            }
            event.close();
        });
    }

    public setHost() {
        if (this.model.settings.search_host) {
            this.service.Overlay.openModal('user-search', { data: { host: this.model.form.host } }, (event) => {
                if (event.type === 'Accept') {
                    this.model.form.host = event.data.selected;
                    this.updateDisplay();
                }
                event.close();
            });
        } else {
            this.service.Overlay.openModal('set-host', { data: { host: this.model.form.host } }, (event) => {
                if (event.type === 'Accept') {
                    this.model.form.host = event.data.form;
                    this.updateDisplay();
                }
                event.close();
            });
        }
    }

    public checkFields() {
        let valid = true;
        this.model.error = {};
        for (const f of this.model.fields) {
            if (f.show && (!f.hide || f.hide && this.model.form[f.hide])) {
                if (f.required && !this.model.form[f.id] && f.type !== 'multi') {
                    this.model.error[f.id] = { message: `${f.name} ${f.name[f.name.length - 1] === 's' ? 'are' : 'is'} required` };
                } else if (f.required && f.type === 'multi') {
                    for (const field of f.subfields) {
                        if (!this.model.form[`${f.id}.${field.id}`]) {
                            this.model.error[f.id] = { message: `${f.name} ${f.name[f.name.length - 1] === 's' ? 'are' : 'is'} required` };
                            break;
                        }
                    }
                } else if (this.checklist[f.id] instanceof Function) {
                    this.model.error[f.id] = this.checklist[f.id](this.model.form[f.id]);
                }
            }
            valid = valid && !this.model.error[f.id];
        }
        // Check terms
        if (this.model.settings && this.model.settings.terms && !this.model.form.terms) {
            this.model.error.terms = { message: 'You are required to accept the terms and conditions' };
            valid = false;
        }
        this.valid = valid;
        if (this.settings && this.settings.hide_errors) {
            this.model.error = {};
        }
        this.validChange.emit(this.valid);
    }

    public toggleBlock(name: string) {
        if (!this.model.show) { this.model.show = {}; }
        this.model.show[name] = !this.model.show[name];
    }

    public updateSettings() {
        this.model.key = `STAFF.${this.settings && this.settings.edit ? 'edit_' : ''}booking_form`;
    }

    public clearStore() {
        if (localStorage) { localStorage.removeItem(this.model.key); }
    }

    public storeForm(emit: boolean = true, force: boolean = false) {
        if (this.timers.store) {
            clearTimeout(this.timers.store);
            this.timers.store = null;
        }
        if (!force) {
            this.timers.store = setTimeout(() => this.saveForm(emit), 300);
        } else {
            this.saveForm(emit);
        }
    }

    public saveForm(emit: boolean) {
        if (!this.model.form) { return; }
        if (localStorage && (!this.model.page || this.model.page.indexOf('result') < 0)) {
            localStorage.setItem(this.model.key, JSON.stringify(this.model.form));
        }
        if (emit) {
            this.form = JSON.parse(JSON.stringify(this.model.form));
            this.formChange.emit(this.form);
        }
        this.timers.store = null;
    }

    public loadForm() {
        if (localStorage) {
            this.model.form = JSON.parse(localStorage.getItem(this.model.key) || '{}');
            if (this.model.form.date) { this.model.old_form = true; }
            if (this.model.form.loan_items) {
                for (const i of this.model.loan_items) {
                    i.active = this.model.form.loan_items.indexOf(i.id) >= 0;
                }
            }
        }
        this.checkAttendees();
    }

    public loadOldBooking() {
        if (localStorage) {
            this.model.form = JSON.parse(localStorage.getItem(this.model.key) || '{}');
            if (this.model.form.date) { this.model.old_form = true; }
        }
        this.checkAttendees();
    }

    public checkAttendees() {
        if (!this.model.error) { this.model.error = {}; }
        if (this.checklist.attendees) {
            this.model.error.attendees = this.checklist.attendees(this.model.form.attendees);
        }
    }

    public clearForm() {
        this.clearStore();
        this.model.form = {};
        for (const f of this.model.field_list) {
            if (f.type === 'multi') {
                this.model.form[f.id] = {};
            }
        }
        if (!this.model.form.duration) { this.model.form.duration = this.service.Settings.get('app.booking.default_length') || 60; }
        if (!this.model.form.recurr) { this.model.form.recurr = 0; }
        const now = moment().seconds(0).milliseconds(0);
        now.minutes(Math.ceil(now.minutes() / 5) * 5);
        this.model.form.date = now.valueOf();
        this.model.form_checked = false;
        this.model.old_form = false;
        if (this.model.loan_items) {
            for (const i of this.model.loan_items) {
                i.active = false;
            }
        }
        this.storeForm();
        this.updateDisplay();
    }

    public back() {
        this.service.navigate(this.model.back || '');
    }

    public viewTerms() {
        this.service.viewTerms();
    }

    public updateFilters(filters: any) {
        if (filters) {
            for (const k in filters) {
                if (filters.hasOwnProperty(k) && k !== 'room') {
                    this.model.form[k] = filters[k];
                }
            }
            this.storeForm();
            this.updateDisplay();
        }
    }

    public updateLoanItems() {
        if (this.model.loan_items) {
            this.model.form.loan_items = '';
            for (const i of this.model.loan_items) {
                if (i.active) {
                    if (this.model.form.loan_items) { this.model.form.loan_items += ','; }
                    this.model.form.loan_items += i.id;
                }
            }
            this.storeForm();
            this.updateDisplay();
        }
    }

    public evnt(id: string) {
        this.saveForm(true);
        if (this.events[id] instanceof Function) { this.events[id](); }
    }

    private checkFieldConditions() {
        for (const f of this.model.fields) {
            f.show = true;
            if (f.conditions) {
                for (const key in f.conditions) {
                    if (f.conditions.hasOwnProperty(key)) {
                        f.show = (f.conditions[key] === this.model.settings[key] || (this.settings.edit && f.view_edit)) && f.show;
                    }
                }
            }
        }
    }

    private updateDisplay(store: boolean = true) {
        if (this.timers.display) {
            clearTimeout(this.timers.display);
            this.timers.display = null;
        }
        this.timers.display = setTimeout(() => {
            this.checkFieldConditions();
            if (!this.model.user) {
                this.model.user = this.service.Users.current();
            }
            const form = this.model.form || {};
            this.model.display = {};
            if (form.date) {
                const date = moment(form.date);
                this.model.display.date = date.format('dddd, Do MMMM, YYYY');
                this.model.display.time = `${date.format('h:mma')}`;
                if (form.duration) {
                    const end = moment(date).add(form.duration, 'm');
                    const h = Math.floor(form.duration / 60);
                    let d = `${h >= 1 ? (h + ' hour' + (h === 1 ? '' : 's')) : '' }`;
                    if (form.duration % 60 !== 0) {
                        if (d) { d += ', '; }
                        const m = form.duration % 60;
                        d += `${m >= 1 ? (m + ' minute' + (m === 1 ? '' : 's')) : '' }`;
                    }
                    this.model.display.duration = `${date.format('h:mma')} – ${end.format('h:mma')}(${d})`;
                }
            }
            if (form.recurr_end) {
                const end = moment(form.recurr_end);
                this.model.display.recurr_end = end.format('dddd, Do MMMM, YYYY');
            }
            if (form.room) {
                this.model.display.room = form.room.name;
                if (form.room.capacity) {
                    this.model.display.room += `(${form.room.capacity} ${form.room.capacity === 1 ? 'Person' : 'People'})`;
                }
            }
            if (this.model.user) {
                this.model.display.organiser = this.model.user.name;
            }
            if (form.attendees) {
                if (form.attendees.length > 0) {
                    for (const u of this.model.form.attendees) {
                        if (this.model.user && this.model.user.email === u.email) {
                            this.model.form.attendees.splice(this.model.form.attendees.indexOf(u), 1);
                            break;
                        }
                    }
                    this.model.form.attendees.unshift(this.model.user);
                    this.model.display.attendees = `${form.attendees.length} Attendee${form.attendees.length === 1 ? '' : 's'}; `;
                    let start = false;
                    for (const user of form.attendees) {
                        if (start) {
                            this.model.display.attendees += ', ';
                        } else {
                            start = true;
                        }
                        this.model.display.attendees += user ? user.name : 'You';
                    }
                    this.model.form.attendees.shift(this.model.user);
                } else {
                    this.model.display.attendees = `1 Attendee; ${this.model.user ? this.model.user.name || 'You' : 'You'}`;
                }
            } else {
                this.model.display.attendees = `1 Attendee; ${this.model.user ? this.model.user.name || 'You' : 'You'}`;
            }
            if (this.model.form.room) {
                this.model.form.cost = this.model.form.room.rate * (this.model.form.duration / 60);
                this.storeForm(store);
            }
            if (form.catering) {
                this.model.display.catering = `${form.catering.total} item${form.catering.total > 1 ? 's' : ''}`;
                this.model.display.catering += `; $${(form.catering.total_price / 100).toFixed(2)}`;
                if (form.catering.details) {
                    this.model.display.catering += `; Dietary information included`;
                }
            }
            if (form.host) {
                this.model.display.host = form.host.name || form.host.email.split('@')[0];
            }
            this.timers.display = null;
        }, 100);
    }
}
