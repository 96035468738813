
import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppService } from '../../services/app.service';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '../../shared/components/base.component';

@Component({
    selector: 'app-welcome',
    templateUrl: './welcome.template.html',
    styleUrls: ['./welcome.styles.scss']
})
export class WelcomeComponent extends BaseComponent implements OnInit, OnDestroy {

    public model: any = {};

    constructor(private service: AppService, private route: ActivatedRoute) {
        super();
    }

    public ngOnInit() {
        super.ngOnInit();
        this.init();
        this.model.route_sub = this.route.paramMap.subscribe((params) => {
            if (params.has('level')) {
                this.model.level = params.get('level');
            }
        });
        this.model.level_sub = this.service.listen('KIOSK.level', (lvl) => {
            this.model.level = lvl;
        });
    }

    public ngOnDestroy() {
        if (this.model.route_sub) {
            this.model.route_sub.unsubscribe();
            this.model.route_sub = null;
        }
    }

    public init() {
        if (!this.service.Settings.setup) {
            return setTimeout(() => this.init(), 500);
        }
        this.model.background = this.service.Settings.get('app.welcome.bg');
    }

    public goto(id: string) {
        if (id) {
            const route = [id];
            if (id === 'explore') {
                route.push(this.model.level);
            }
            this.service.navigate(route);
        }
    }
}
