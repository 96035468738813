import { OverlayContentComponent } from '@acaprojects/ngx-widgets';
import { Component } from '@angular/core';

import * as moment from 'moment';

@Component({
    selector: 'host-modal',
    templateUrl: './host-modal.template.html',
    styleUrls: ['./host-modal.styles.scss'],
})
export class HostModalComponent extends OverlayContentComponent {
    public model: any = {};

    public init() {
        this.model.form = {};
        this.model.focus = {};
        if (this.model.host) {
            for (const k in this.model.host) {
                if (this.model.host.hasOwnProperty(k)) {
                    this.model.form[k] = this.model.host[k];
                }
            }
        }
    }

    public close() {
        setTimeout(() => this.fn.close(), 300);
    }

    public event(name: string) {
        this.model.error = {};
        if (this.model.form && this.model.form.name && this.model.form.name[this.model.form.name.length - 1] === ' ') {
            this.model.form.name.splice(this.model.form.name.length - 1, 1);
        }
        const regex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
        if (!this.model.form || !this.model.form.email || !regex.test(this.model.form.email)) {
            this.model.error.email = { message: 'A valid email is required' };
            return;
        }
        setTimeout(() => this.fn.event(name), 300);
    }
}
