/*
 * @Author: alex.sorafumo
 * @Date:   2017-04-03 15:50:46
 * @Last Modified by: Alex Sorafumo
 * @Last Modified time: 2018-06-19 13:24:24
 */

import { MOCK_REQ_HANDLER } from '@acaprojects/ngx-composer';
import { BehaviorSubject } from 'rxjs';

import { BaseMockBackend } from './base.mock';

import * as faker from 'faker';
import * as moment from 'moment';

export class MockRoomsBackend extends BaseMockBackend {
    protected load() {
        if (!this.model) { this.model = {}; }
        this.loadRooms();
    }

    private loadRooms() {
        if (this.model.log) { this.model.log('MOCK', 'Loading request for room list'); }

        const room_list: any[] = [
                // Sydney Level 2
            { name: 'Meet', area: '02.01', capacity: 6, bookable: true, zone: 'zone_lvl-02' },
            { name: 'VC', area: '02.02', capacity: 4, bookable: true, zone: 'zone_lvl-02' },
            { name: 'Focus', area: '02.03', capacity: 4, bookable: true, zone: 'zone_lvl-02' },
            { name: 'Focus', area: '02.04', capacity: 4, bookable: true, zone: 'zone_lvl-02' },
            { name: 'VC', area: '02.05', capacity: 4, bookable: true, zone: 'zone_lvl-02' },
            { name: 'Focus', area: '02.06', capacity: 4, bookable: true, zone: 'zone_lvl-02' },
            { name: 'VC', area: '02.07', capacity: 4, bookable: true, zone: 'zone_lvl-02' },
            { name: 'VC', area: '02.08', capacity: 4, bookable: true, zone: 'zone_lvl-02' },
            { name: 'Meet', area: '02.09', capacity: 6, bookable: true, zone: 'zone_lvl-02' },
            { name: 'Meet', area: '02.10', capacity: 12, bookable: true, zone: 'zone_lvl-02' },
                // Sydney Level 3
            { name: 'Meet', area: '03.01', capacity: 6, bookable: true, zone: 'zone_lvl-03' },
            { name: 'VC', area: '03.02', capacity: 4, bookable: true, zone: 'zone_lvl-03' },
            { name: 'Focus', area: '03.03', capacity: 4, bookable: true, zone: 'zone_lvl-03' },
            { name: 'Focus', area: '03.04', capacity: 4, bookable: true, zone: 'zone_lvl-03' },
            { name: 'VC', area: '03.05', capacity: 4, bookable: true, zone: 'zone_lvl-03' },
            { name: 'Focus', area: '03.06', capacity: 4, bookable: true, zone: 'zone_lvl-03' },
            { name: 'VC', area: '03.07', capacity: 4, bookable: true, zone: 'zone_lvl-03' },
            { name: 'VC', area: '03.08', capacity: 4, bookable: true, zone: 'zone_lvl-03' },
            { name: 'Meet', area: '03.09', capacity: 6, bookable: true, zone: 'zone_lvl-03' },
            { name: 'Studio A', area: '03.10', capacity: 12, bookable: true, zone: 'zone_lvl-03' },
            { name: 'Meet', area: '03.13', capacity: 8, bookable: true, zone: 'zone_lvl-03' },
            { name: 'Meet', area: '03.15', capacity: 14, bookable: true, zone: 'zone_lvl-03' },
            { name: 'Wellness', area: '03.16', capacity: 3, bookable: true, zone: 'zone_lvl-03' },
                // Sydney Level 5
            { name: 'Meet', area: '05.01', capacity: 6, bookable: true, zone: 'zone_lvl-05' },
            { name: 'VC', area: '05.02', capacity: 4, bookable: true, zone: 'zone_lvl-05' },
            { name: 'Focus', area: '05.03', capacity: 4, bookable: true, zone: 'zone_lvl-05' },
            { name: 'Focus', area: '05.04', capacity: 4, bookable: true, zone: 'zone_lvl-05' },
            { name: 'VC', area: '05.05', capacity: 4, bookable: true, zone: 'zone_lvl-05' },
            { name: 'Focus', area: '05.06', capacity: 4, bookable: true, zone: 'zone_lvl-05' },
            { name: 'VC', area: '05.07', capacity: 4, bookable: true, zone: 'zone_lvl-05' },
            { name: 'VC', area: '05.08', capacity: 4, bookable: true, zone: 'zone_lvl-05' },
            { name: 'Meet', area: '05.09', capacity: 6, bookable: true, zone: 'zone_lvl-05' },
            { name: 'Good Room', area: '05.10', capacity: 24, bookable: true, zone: 'zone_lvl-05' },
            { name: 'Meet', area: '05.09', capacity: 6, bookable: true, zone: 'zone_lvl-05' },
            { name: 'Meet', area: '05.11', capacity: 8, bookable: true, zone: 'zone_lvl-05' },
            { name: 'Meet', area: '05.12', capacity: 8, bookable: true, zone: 'zone_lvl-05' },
            { name: 'Meet', area: '05.14', capacity: 14, bookable: true, zone: 'zone_lvl-05' },
                // Sydney Level 6
            { name: 'Focus', area: '06.01', capacity: 4, bookable: true, zone: 'zone_lvl-06' },
            { name: 'VC', area: '06.02', capacity: 4, bookable: true, zone: 'zone_lvl-06' },
            { name: 'VC', area: '06.03', capacity: 4, bookable: true, zone: 'zone_lvl-06' },
            { name: 'Meet', area: '06.04', capacity: 6, bookable: true, zone: 'zone_lvl-06' },
            { name: 'Studio', area: '06.05', capacity: 12, bookable: true, zone: 'zone_lvl-06' },
                // Melbourne Level 1
            { name: 'Studio', area: '1.01', capacity: 24, bookable: true, zone: 'zone_lvl-m1' },
            { name: 'Studio', area: '1.02', capacity: 24, bookable: true, zone: 'zone_lvl-m1' },
            { name: 'Experimental Lab', area: '1.04', capacity: 8, bookable: true, zone: 'zone_lvl-m1' },
            { name: 'Meeting Room', area: '1.05', capacity: 8, bookable: true, zone: 'zone_lvl-m1' },
            { name: 'Meeting Room', area: '1.06', capacity: 8, bookable: true, zone: 'zone_lvl-m1' },
            { name: 'Focus', area: '1.07', capacity: 3, bookable: true, zone: 'zone_lvl-m1' },
            { name: 'Focus', area: '1.08', capacity: 3, bookable: true, zone: 'zone_lvl-m1' },
            { name: 'Focus', area: '1.09', capacity: 4, bookable: true, zone: 'zone_lvl-m1' },
            { name: 'Focus', area: '1.10', capacity: 4, bookable: true, zone: 'zone_lvl-m1' },
            { name: 'Media Table', area: '1.12', capacity: 4, bookable: true, zone: 'zone_lvl-m1' },
            { name: 'Project Space', area: '1.13', capacity: 10, bookable: true, zone: 'zone_lvl-m1' },
            { name: 'Project Space', area: '1.14', capacity: 10, bookable: true, zone: 'zone_lvl-m1' },
                // Melbourne Level 2
            { name: 'Boardroom', area: '2.01', capacity: 24, bookable: true, zone: 'zone_lvl-m2' },
            { name: 'Meeting Room', area: '2.02', capacity: 8, bookable: true, zone: 'zone_lvl-m2' },
            { name: 'Studio', area: '2.03', capacity: 12, bookable: true, zone: 'zone_lvl-m2' },
            { name: 'Studio', area: '2.04', capacity: 12, bookable: true, zone: 'zone_lvl-m2' },
            { name: 'Meeting Room', area: '2.05', capacity: 8, bookable: true, zone: 'zone_lvl-m2' },
            { name: 'Meeting Room', area: '2.06', capacity: 8, bookable: true, zone: 'zone_lvl-m2' },
            { name: 'Light Lab', area: '2.08', capacity: 4, bookable: true, zone: 'zone_lvl-m2' },
            { name: 'Knowledge Hub', area: '2.09', capacity: 12, bookable: true, zone: 'zone_lvl-m2' },
            { name: 'Digital Review', area: '2.10', capacity: 12, bookable: true, zone: 'zone_lvl-m2' },
            { name: 'Lower Mezzanine', area: '2.11', capacity: 12, bookable: true, zone: 'zone_lvl-m2' },
                // Melbourne Level 3
            { name: 'Activity Center', area: '03.01', capacity: 24, bookable: true, zone: 'zone_lvl-m3' },
            { name: 'Retreat', area: '3.03', capacity: 4, bookable: true, zone: 'zone_lvl-m3' },
            { name: 'SoundLab', area: '3.04', capacity: 6, bookable: true, zone: 'zone_lvl-m3' },
            { name: 'Meeting Room(VC)', area: '3.05', capacity: 4, bookable: true, zone: 'zone_lvl-m3' },
            { name: 'Meeting Room(VC)', area: '3.06', capacity: 4, bookable: true, zone: 'zone_lvl-m3' },
            { name: 'Meeting Room', area: '3.09', capacity: 4, bookable: true, zone: 'zone_lvl-m3' },
            { name: 'Meeting Room', area: '3.12', capacity: 4, bookable: true, zone: 'zone_lvl-m3' },
            { name: 'Media Table', area: '3.11', capacity: 4, bookable: true, zone: 'zone_lvl-m3' },
            { name: 'Media Table', area: '3.14', capacity: 4, bookable: true, zone: 'zone_lvl-m3' },
            { name: 'Makers Space', area: '3.10', capacity: 4, bookable: true, zone: 'zone_lvl-m3' },
            { name: 'Focus', area: '3.07', capacity: 4, bookable: true, zone: 'zone_lvl-m3' },
            { name: 'Focus', area: '3.08', capacity: 4, bookable: true, zone: 'zone_lvl-m3' },
        ];

        const bld = this.model.bld_data[Object.keys(this.model.bld_data)[0]];
        const extras = bld.settings.discovery_info.extras;
        const rooms: any[] = [];
        for (const room of room_list) {
            const id = `sys_rm-${room.area}`;
            let extra_list = '';
            for (let i = 0; i < 2; i++) {
                if (extra_list) { extra_list += ' '; }
                const e = extras[Math.floor(Math.random() * extras.length)];
                if (extra_list.indexOf(e.extra_id) < 0) { extra_list += e.extra_id; }
            }
            rooms.push({
                id,
                name: `${room.name} ${room.area}`,
                email: `${this.model.city}-${room.area}@${this.model.domain}`,
                bookable: room.bookable,
                capacity: room.capacity || Math.floor(Math.random() * 32 + 2),
                features: '',
                zones: [room.zone || 'zone_lvl-01'],
                settings: {
                    bookable: room.bookable,
                    map_id: `${room.area}`,
                    bookings: [],
                    room_id: id,
                    extra_features: extra_list,
                    cost_hour: room.cost || 0
                },
                support_url: `http://aca.engin.run/demo/aca/control/` // `/meeting/#/?ctrl=${id}`
            });
        }
        this.model.rooms = rooms;

        MOCK_REQ_HANDLER.register(`/${this.model.api_route}/rooms/:id`, this.model.rooms, (event) => {
            if (event.params.id) {
                if (event.fragment.available_from) {
                    const start = moment(event.fragment.available_from * 1000);
                    const end = event.fragment.available_to ? moment(event.fragment.available_to * 1000) : moment(start).add(60, 'm');
                    const available = this.getAvailableRooms(event.data, start.valueOf(), moment.duration(end.diff(start)).asMinutes());
                    for (const rm of available) {
                        if (rm.id === event.params.id) {
                            rm.available = true;
                            return rm;
                        }
                    }
                    return { id: event.params.id, available: false, bookable: true };
                } else {
                    for (const rm of event.data) {
                        if (event.params.id === rm.id) {
                            return rm;
                        }
                    }
                }
            }
            return null;
        });

            // Get bookable rooms from room list
        MOCK_REQ_HANDLER.register(`/${this.model.api_route}/rooms`, this.model.rooms, (event) => {
            if (event.fragment.available_from) {
                const start = moment(event.fragment.available_from * 1000);
                const end = event.fragment.available_to ? moment(event.fragment.available_to * 1000) : moment(start).add(60, 'm');
                return this.getAvailableRooms(event.data, start.valueOf(), moment.duration(end.diff(start)).asMinutes());
            } else {
                const results: any[] = [];
                for (const i of event.data) {
                    if (i.bookable) {
                        i.available = Math.floor(Math.random() * 2349873421) % 3 === 0;
                        results.push(i);
                    }
                }
                return results;
            }
        });
        this.state.next(true);
    }

    private getAvailableRooms(list: any[], start_time: number, duration: number = 60) {
        const results = [];
        const start = moment(start_time);
        const end = moment(start).add(duration, 'm');
        for (const rm of list) {
            let clash = false;
            for (const bkn of rm.settings.bookings) {
                const bkn_start = moment(bkn.Start);
                const bkn_end = moment(bkn.End);
                if (start.isBetween(bkn_start, bkn_end, 'm', '[)') || end.isBetween(bkn_start, bkn_end, 'm', '(]')) {
                    clash = true;
                }
            }
            if (!clash) {
                rm.available = true;
                rm.settings.available = true;
                results.push(rm);
            }
        }
        return results;
    }
}
