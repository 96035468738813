/**
* @Author: Alex Sorafumo <alex.sorafumo>
* @Date:   11/01/2017 4:16 PM
* @Email:  alex@yuion.net
* @Filename: mock-system.ts
* @Last modified by:   Alex Sorafumo
* @Last modified time: 03/02/2017 2:26 PM
*/

import { MOCK_REQ_HANDLER } from '@acaprojects/ngx-composer';

import * as moment from 'moment';

const win = self as any;

win.systemData = win.systemData || {};
win.control = win.control || {};
win.control.systems = win.control.systems || {};
win.control.systems['sys-B0'] = {
    System: [{
        name: 'Demo System',
    }],
    Demo: [{
        volume: 0,
        mute: false,
        views: 0,
        state: 'Idle',

        $play: () => {
            win.control.systems['sys-B0'].Demo[0].state = 'Playing';
        },

        $stop: () => {
            win.control.systems['sys-B0'].Demo[0].state = 'Stopped';
        },

        $volume: (value: number) => {
            this.volume = value;
            if (this.volume > 100) {
                this.volume = 100;
            } else if (this.volume < 0) {
                this.volume = 0;
            }
        },

        $mute: (state: boolean) => {
            this.mute = state;
        },

        $state: (status: string) => {
            this.state = status;
        },
    }],
    FloorManagement: [{
        'zone-lvl_02': {
            'zone-2.N-status': { capacity: 100, people_count: Math.floor(Math.random() * 80 + 10) },
            'zone-2.E-status': { capacity: 100, people_count: Math.floor(Math.random() * 80 + 10) },
            'zone-2.S-status': { capacity: 100, people_count: Math.floor(Math.random() * 80 + 10) },
            'zone-2.W-status': { capacity: 100, people_count: Math.floor(Math.random() * 80 + 10) }
        }
    }],
    DeskManagement: [{
        $desk_details: function (desk_id: string) {
            const occupiedKeys = Object.keys(this).filter(f => f.endsWith(':occupied'));
            for (const key of occupiedKeys) {
                const found = this[key].filter(f => f.desk_id === desk_id);
                if (found.length) {
                    return [found[0]];
                }
            }

            const outOfOrderKeys = Object.keys(this).filter(f => f.endsWith(':out_of_order'));
            for (const key of outOfOrderKeys) {
                const found = this[key].filter(f => f === desk_id);
                if (found.length) {
                    return [{
                        'ip': '10.45.66.63',
                        'mac': '725a0fd1f6e3',
                        'connected_at': 1538604292,
                        'connected': true,
                        'clash': false,
                        'reserved': false,
                        'desk_id': desk_id,
                        'out_of_order': true,
                    }];
                }
            }

            const deskBankReservationKeys = Object.keys(this).filter(f => f.endsWith(':reservations'));
            for (const key of deskBankReservationKeys) {
                const found = this[key].filter(f => f.desk_id === desk_id);
                if (found.length) {
                    return [found[0]];
                }
            }

            return [
                {
                  'ip': '10.45.66.63',
                  'mac': '725a0fd1f6e3',
                  'connected_at': 1538604292,
                  'connected': true,
                  'clash': false,
                  'reserved': true,
                  'username': 'john.King',
                  'desk_id': 'table-MEL.2.08.E'
                }
              ];
        },
        $desk_usage: (level: string) => this[`${level}`] + this[`${level}:reserved`],
        $manual_checkin: function (desk_id: string, level_id: string = 'zone_Fd-20') {
            if (!this[`${level_id}`]) { this[`${level_id}`] = []; }
            this[`${level_id}`] = this[`${level_id}`].concat([desk_id]);
            if (!this[`${level_id}:clashes`]) { this[`${level_id}:clashes`] = []; }
            this[`${level_id}:clashes`] = this[`${level_id}:clashes`].concat([desk_id]);
            if (!this[`${level_id}:occupied_count`]) { this[`${level_id}:occupied_count`] = 0; }
            this[`${level_id}:occupied_count`] += 1;
            if (!this[`${level_id}:free_count`]) { this[`${level_id}:free_count`] = 1; }
            this[`${level_id}free_count`] -= 1;
            if (win.backend) {
                const user = win.backend.model.user;
                this[user.win_id] = {
                    connected: true,
                    manual_desk: true,
                    desk_id,
                    unplug_time: moment().unix()
                };
            }
        },
        // Will reserve the desk that is indicated above
        $reserve_desk: () => {
            if (win.backend) {
                const user = win.backend.model.user;
                if (this[user.win_id]) {
                    this[user.win_id].reserved = true;
                    this[user.win_id].unplug_time = moment().unix();
                }
            } else {
                if (this.user) {
                    this.user.reserved = true;
                    this.user.unplug_time = moment().unix();
                }
            }
        },
        $cancel_reservation: () => {
            if (win.backend) {
                const user = win.backend.model.user;
                if (this[user.win_id]) {
                    this[user.win_id] = {
                        connected: false,
                        reserved: false,
                        unplug_time: moment().unix()
                    };
                }
            } else {
                if (this.user) {
                    this.user = {
                        connected: false,
                        reserved: false,
                        unplug_time: moment().unix()
                    };
                }
            }
        },
    }]
};

win.systemData['sys-B0'] = win.control.systems['sys-B0'];
