import { OverlayContentComponent } from '@acaprojects/ngx-widgets';
import { Component } from '@angular/core';

import * as moment from 'moment';

@Component({
    selector: 'time-period-modal',
    templateUrl: './time-period-modal.template.html',
    styleUrls: ['./time-period-modal.styles.scss'],
})
export class TimePeriodModalComponent extends OverlayContentComponent {
    public model: any = {};

    public init() {
        if (!this.service || !this.service.Settings.setup) {
            return setTimeout(() => this.init(), 300);
        }
        this.model.form = {};
        this.model.display = {};
        this.model.focus = {};
        this.model.min_duration = this.service.Settings.get('app.booking.min_length') || 30;
        this.model.max_duration = this.service.Settings.get('app.booking.max_length') || 480;
        if (!this.model.duration) {
            this.model.duration = this.service.Settings.get('app.booking.default_length') || 60;
        }
        if (!this.model.end) { this.model.simple = true; }
        const now = moment();
        now.minutes(Math.ceil(now.minutes() / 5) * 5);
            // Set start and end times
        this.model.form.start = this.model.start || now.format('HH:mm');
        this.model.form.end = this.model.end || now.add(this.model.duration || 60, 'm').format('HH:mm');
            // Setup duration
        if (this.model.form.start && this.model.form.end) {
            const start = moment().hours(this.model.form.start.split(':')[0]).minutes(this.model.form.start.split(':')[1]);
            const end = moment().hours(this.model.form.end.split(':')[0]).minutes(this.model.form.end.split(':')[1]);
            this.model.duration = Math.floor(Math.abs(moment.duration(start.diff(end)).asMinutes()));
        }
    }

    public close() {
        setTimeout(() => this.fn.close(), 300);
    }

    public event(name: string) {
        const start = moment().hours(this.model.form.start.split(':')[0]).minutes(this.model.form.start.split(':')[1]);
        start.minutes(Math.ceil(start.minutes() / 5) * 5);
        this.model.form.start = start.format('HH:mm');
        const end = moment(start).add(this.model.duration, 'm');
        this.model.form.end = end.format('HH:mm');
        this.model.form.duration = Math.floor(Math.abs(moment.duration(start.diff(end)).asMinutes()));
        setTimeout(() => this.fn.event(name), 300);
    }
}
