import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

const _IDLE = '';
const _RESERVED = 'RESERVED';
const _OUT_OF_ORDER = 'OUT_OF_ORDER';
const _RESET = 'RESET';
const _CHECKIN = 'CHECKIN';
const _GROUP_RESERVATION = 'GROUP_RESERVATION';

export interface IDeskSelection {
  selectionMode: string;
  selectedDesks: string[];
  tag: string;
  submitReserved: Date;
  submitReset: Date;
  submitOutOfOrder: Date;
  submitOccupied: Date;
}

const initialState = (): IDeskSelection => ({
  selectionMode: _IDLE,
  selectedDesks: [],
  tag: '',
  submitReserved: new Date(),
  submitReset: new Date(),
  submitOutOfOrder: new Date(),
  submitOccupied: new Date()
});

@Injectable({
    providedIn: 'root'
})
export class DeskOptionsService {
  public IDLE = _IDLE;
  public RESERVED = _RESERVED;
  public OUT_OF_ORDER = _OUT_OF_ORDER;
  public RESET = _RESET;
  public CHECKIN = _CHECKIN;
  public GROUP_RESERVATION = _GROUP_RESERVATION;

  private subjects: any = {};

  constructor() {
    this.subjects.deskSelection = new BehaviorSubject<IDeskSelection>(initialState());
  }

  public init() {
    // TODO: something?
  }

  public listen(next: (data: any) => void) {
      return this.subjects.deskSelection.subscribe(next);
  }

  private getState() {
    return this.subjects.deskSelection.value;
  }

  private setState(state: IDeskSelection) {
    this.subjects.deskSelection.next(Object.assign({}, state));
  }

  public setSelectionMode(selectionMode: string) {
    this.clearSelectedDesks();
    this.clearTag();
    const state = this.getState();
    state.selectionMode = selectionMode;
    this.setState(state);
  }

  public onDeskSelected(deskId: string) {
    const state = this.getState();
    if (state.selectedDesks.indexOf(deskId) > -1) {
      state.selectedDesks = state.selectedDesks.filter(f => f !== deskId);
    } else {
      // only one desk at a time for these options
      if (state.selectionMode === this.OUT_OF_ORDER || state.selectionMode === this.CHECKIN) {
        state.selectedDesks = [];
      }
      state.selectedDesks.push(deskId);
    }
    this.setState(state);
  }

  public clearSelectedDesks() {
    const state = this.getState();
    state.selectedDesks = [];
    this.setState(state);
  }

  public clearSelectionMode() {
    const state = this.getState();
    state.selectionMode = this.IDLE;
    this.setState(state);
  }

  public clearTag() {
    const state = this.getState();
    state.tag = '';
    this.setState(state);
  }

  public reset() {
    this.clearSelectedDesks();
    this.clearSelectionMode();
    this.clearTag();
  }

  public confirm() {
    const state = this.getState();
    switch (state.selectionMode) {
      case _GROUP_RESERVATION: state.submitReserved = new Date(); break;
      case _OUT_OF_ORDER: state.submitOutOfOrder = new Date(); break;
      case _RESET: state.submitReset = new Date(); break;
      case _CHECKIN: state.submitOccupied = new Date(); break;
      default: break;
    }
    this.setState(state);
  }

  public getSelectionModeClass(selectionMode: string) {
    switch (selectionMode) {
      case _RESERVED: return 'reserved';
      case _OUT_OF_ORDER: return 'out-of-order';
      case _RESET: return 'reset';
      case _CHECKIN: return 'unavailable';
      case _GROUP_RESERVATION: return 'reserved-group';
      default: return '';
    }
  }
}
