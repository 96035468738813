
import { Component, EventEmitter, Input, Output, ViewChild, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationStart, NavigationEnd } from '@angular/router';

import { AppService } from '../../../../services/app.service';
import { Utils } from '../../../../shared/utility.class';
import { IUser } from '../../../../services/data/users.service';
import { IRoom } from '../../../../services/data/rooms.service';
import { BaseComponent } from '../../../../shared/components/base.component';

@Component({
    selector: 'explore-search',
    templateUrl: './search.template.html',
    styleUrls: ['./search.styles.scss'],
})
export class ExploreSearchComponent extends BaseComponent implements OnInit {
    @Input() public item: any = null;
    @Output() public itemChange: any = new EventEmitter();
    public model: any = {};
    public filtered_items: any[] = [];

    @ViewChild('input', { static: false }) private input: ElementRef;

    private timers: any = {};

    constructor(private service: AppService, private route: ActivatedRoute, private router: Router) {
        super();
    }

    public ngOnInit() {
        const win = self as any;
        this.model.loaded = false;
        this.model.speech = !!(win.SpeechRecognition || win.webkitSpeechRecognition);
        this.model.settings = {};
        this.subs.obs.users = this.service.Users.listen('user_list', (list) => {
            this.model.user_list = list || [];
            this.model.user_list.sort((a, b) => a.name.localeCompare(b.name));
            this.filter();
        });
        this.subs.obs.rooms = this.service.Rooms.listen('room_list', () => {
            this.model.room_list = this.service.Rooms.list();
            this.filter();
        });

        this.subs.obs.bld = this.service.Buildings.listen((bld) => {
            if (bld) {
                this.model.search = '';
                this.model.room_list = this.service.Rooms.list();
                this.filter();
            }
        });
        this.subs.obs.query = this.route.queryParamMap.subscribe((params) => {
            if (params.has('role')) {
                this.model.role_param = params.get('role');
                this.initRole();
            }
        });
        this.subs.obs.route = this.route.paramMap.subscribe((params) => {
            if (params.has('search')) {
                this.model.search_type = params.get('search');
                this.updateShow();
                this.filter();
            }
        });
            // Clear results when route changes
        this.subs.obs.routing = this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                this.filtered_items = [];
                this.model.search = '';
                this.service.set('BANNER.block_height', 0);
            } else if (event instanceof NavigationEnd) {
                this.updateShow();
            }
        });
        this.subs.obs.clear = this.service.listen('KIOSK.has_reset', () => {
            this.model.search = '';
            this.filter();
            this.model.show = false;
        });
        this.init();
    }

    public init() {
        const bld = this.service.Buildings.current();
        if (!this.service.Settings.setup || !bld) {
            return setTimeout(() => this.init(), 500);
        }
        this.model.route = this.service.Settings.get('app.style.popout');
        this.model.min_search = this.service.Settings.get('app.people_min_char') || 0;
        this.model.settings = this.service.Settings.get('app.explore') || {};
        this.model.role = {
            details: this.service.Settings.get('app.roles'),
            list: [],
            active: -1
        };
        if (bld.roles) {
            for (const role in bld.roles) {
                if (bld.roles.hasOwnProperty(role)) {
                    this.model.role.list.push({
                        id: role,
                        name: role.split('-').join(' '),
                        icon: this.model.role.details ? this.model.role.details[role] : null,
                        users: bld.roles[role]
                    });
                }
            }
        }
        this.filter();
        this.updateShow();
        this.model.inited = true;
    }

    public initRole() {
        if (this.model.role_param && this.model.role && this.model.role.list) {
            for (const i of this.model.role.list) {
                if (i.id === this.model.role_param) {
                    this.model.role.active = this.model.role.list.indexOf(i);
                    this.filter();
                    break;
                }
            }
        } else if (!this.model.role || !this.model.role.list) {
            setTimeout(() => this.initRole(), 500);
        }
    }

    /**
     * Update list of filtered items
     */
    public filter(focused: boolean = false) {
        if (!this.model.settings) { return; }
        console.log('Filter with search text:', this.model.search);
        this.model.loading = true;
        this.timeout('loading', () => this.model.loading = false, 310);
            // Prevent deselection o
        if (focused && this.input && this.input.nativeElement) {
            this.input.nativeElement.focus();
            this.model.focus = true;
            this.updateShow();
        }
        if (this.timers.search) {
            clearTimeout(this.timers.search);
            this.timers.search = null;
        }
        if (!this.model.search) {
            return this.filtered_items = [];
        }
        this.timeout('filter', () => {
            this.clearTimer('loading');
            this.model.loading = true;
            if (this.model.search_type === 'spaces') {
                    // Only return rooms/spaces
                this.filterRooms().then((rooms: IRoom[]) => {
                    this.filtered_items = rooms;
                    this.filtered_items.sort((a, b) => a.name.localeCompare(b.name));
                    return (this.model.loading = false);
                });
            } else if (this.model.search_type === 'people') {
                    // Only return people
                this.filterUsers().then((users: IUser[]) => {
                    this.filtered_items = users;
                    return (this.model.loading = false);
                });
            } else {
                    // Return both spaces and people
                this.filterUsers().then((users: IUser[]) => {
                    const role = this.model.role;
                    if (role && role.active >= 0) {
                        this.filtered_items = users;
                        return (this.model.loading = false);
                    } else {
                        this.model.loading = true;
                        this.filterRooms().then((rooms: IRoom[]) => {
                            this.filtered_items = ((users || []) as any[]).concat(rooms);
                            this.filtered_items.sort((a, b) => a.name.localeCompare(b.name));
                            return (this.model.loading = false);
                        });
                    }
                });
            }
        });
    }

    /**
     * Promise of filtered users
     */
    public filterUsers() {
        return new Promise((resolve) => {
            const search = (this.model.search || '').toLowerCase();
            const role = this.model.role;
            if ((search && search.length >= this.model.min_search) || (role && role.active >= 0) || !this.model.min_search) {
                let users: IUser[] = [];
                    // Get filtered users
                if (role && role.active >= 0) {
                    users = this.service.Users.getFilteredUsers(search, role.list[role.active].users);
                    resolve(users);
                } else if (!this.model.min_search && this.model.user_list && this.model.user_list.length > 0) {
                    users = this.service.Users.getFilteredUsers(search, this.model.user_list);
                    resolve(users);
                } else {
                    const request_id = Math.floor(Math.random() * 899999 + 100000);
                    this.model.request_id = request_id;
                    this.service.Users.query({ q: search, limit: 50 })
                        .then((list) => {
                            if (request_id === this.model.request_id) {
                                users = this.service.Users.getFilteredUsers(search, list);
                                resolve(users);
                            }
                        }, () => resolve(users));
                }
            } else {
                resolve([]);
            }
        });
    }

    /**
     * Promise of filtered spaces
     */
    public filterRooms() {
        return new Promise((resolve) => {
            const search = (this.model.search || '').toLowerCase();
            this.model.room_list = this.service.Rooms.list();
            const list = [];
            for (const item of this.model.room_list) {
                list.push({
                    id: item.id,
                    prefix: item.prefix,
                    bookable: item.bookable,
                    map_id: item.map_id,
                    name: item.name,
                    level: item.level
                });
            }
            const bld = this.service.Buildings.current();
            if (bld && bld.map && bld.map.searchables) {
                for (const level in bld.map.searchables) {
                    if (bld.map.searchables.hasOwnProperty(level)) {
                        for (const name in bld.map.searchables[level]) {
                            if (bld.map.searchables[level].hasOwnProperty(name)) {
                                const lvl = this.service.Buildings.getLevel(level);
                                list.push({ map_id: bld.map.searchables[level][name], name, level: lvl, prefix: false });
                            }
                        }
                    }
                }
            }
            if (!search) {
                return resolve(list);
            }
            const rooms: IRoom[] = [];
            for (const rm of list) {
                if (((rm.name || '').toLowerCase().indexOf(search) >= 0 ||
                    (rm.level && (rm.level.name || '').toLowerCase().indexOf(search) >= 0)) && rm.searchable !== false) {
                    rooms.push(rm);
                }
            }
            resolve(rooms);
        });
    }

    /**
     * Post item to find on map
     * @param item Displayed item selected by the user
     */
    public find(item: any) {
        console.log('Find:', item);
        this.item = item;
        this.model.search = item.name;
        this.model.focus = false;
        this.model.dictate = false;
        if (this.input && this.input.nativeElement) {
            this.input.nativeElement.blur();
        }
        this.itemChange.emit(item);
        this.updateShow();
    }

    /**
     * Open telephone URL for item
     * @param item Displayed item selected by the user
     */
    public call(item: any) {
        item.loading = true;
        let phone = '';
        if (!item.phone) {
                // Load user phone number from API
            this.service.Users.show(item.email).then((person) => {
                if (person && person.phone) {
                    phone = Utils.removeChars(person.phone, ' ()_-');
                    location.href = `tel:${phone}`;
                }
                item.loading = false;
            });
        } else {
            phone = Utils.removeChars(item.phone, ' ()_-');
            location.href = `tel:${phone}`;
            item.loading = false;
        }
    }

    public setActive(item: any) {
        this.model.active_item = item;
        this.timeout('active', () => this.model.active_item = null, 1000);
    }

    /**
     * Open support URL for the selected item
     * @param item Selected item
     */
    public control(item) {
        if (item.support_url) {
            window.open(item.support_url, 'blank_');
        }
    }

    /**
     * Close the search and clear the selected item
     */
    public close() {
        this.model.search = '';
        this.item = null;
        this.model.role.active = -1;
        this.filter();
        if (this.input && this.input.nativeElement) {
            this.input.nativeElement.blur();
        }
        this.itemChange.emit(this.item);
    }

    /**
     * Activate dictation search
     */
    public startDictation() {
        if (!this.input) { return; }
        if (this.model.recognition) {
            this.model.recognition.stop();
            this.model.dictate = false;
            this.model.recognition = null;
            return;
        }
        const win = self as any;
        const Speech: any = win.SpeechRecognition || win.webkitSpeechRecognition;
        if (Speech) {
            this.model.recognition = new Speech();

            this.model.recognition.continuous = false;
            this.model.recognition.interimResults = false;

            this.model.recognition.lang = 'en-US';
            this.model.recognition.start();
            this.model.dictate = true;

            this.model.recognition.onresult = (e: any) => {
                    // Update search field with dictation result
                this.input.nativeElement.value = e.results[0][0].transcript;
                this.model.search = e.results[0][0].transcript;
                this.model.recognition.stop();
                this.filter();
                this.model.dictate = false;
            };

            this.model.recognition.onerror = (e: any) => {
                this.model.recognition.stop();
                this.model.dictate = false;
            };
        }
    }

    public updateShow() {
        this.timeout('update_show', () => {
            if (this.model.active_item) {
                this.find(this.model.active_item);
                this.model.active_item = null;
            }
            if (!this.model.search) { this.filtered_items = []; }
            const people = (!this.item && this.model.role.active !== -1) || (this.model.search_type === 'people' && !this.item);
            this.model.show =  people || this.model.focus || this.model.dictate;
            this.model.banner = this.model.settings && this.model.settings.banner && this.model.settings.banner.search;
            const r = this.model.role && this.model.role.list && this.model.role.list.length > 0;
            this.model.banner_roles = this.model.banner && this.model.settings.banner.roles && this.model.search_type !== 'spaces' && r;
            if (this.model.show && this.model.banner) {
                const h = this.model.banner_roles ? 6.5 : 4;
                this.service.set('BANNER.block_height', h);
            } else {
                this.service.set('BANNER.block_height', 0);
            }
        }, 200);
    }
}
