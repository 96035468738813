/*
* @Author: Alex Sorafumo
* @Date:   2017-05-17 12:49:52
 * @Last Modified by: Alex Sorafumo
 * @Last Modified time: 2018-03-21 14:20:46
*/

import { Component, EventEmitter, Input, Output, OnInit, OnChanges } from '@angular/core';

import { AppService } from '../../../services/app.service';

import * as moment from 'moment';
import { BaseComponent } from '../base.component';

@Component({
    selector: 'user-search',
    templateUrl: './user-search.template.html',
    styleUrls: ['./user-search.styles.scss'],
})
export class UserSearchComponent extends BaseComponent implements OnInit, OnChanges {
    @Input() public users: any[] = [];
    @Input() public modal = false;
    @Output() public usersChange: any = new EventEmitter();
    @Output() public close: any = new EventEmitter();

    public model: any = {};

    constructor(private service: AppService) {
        super();
    }

    public ngOnInit() {
        super.ngOnInit();
        this.model.users = this.users || [];
        this.model.state = !this.users || this.users.length <= 0 ? 'select' : 'display';
        setTimeout(() => this.init(), 100);
    }

    public ngOnChanges(changes: any) {
        if (changes.users) {
            setTimeout(() => {
                this.model.users = JSON.parse(JSON.stringify(this.users)) || [];
                for (let i = 0; i < this.model.users.length; i++) {
                    if (!this.model.users[i]) { this.model.users.splice(i--, 1); }
                }
                this.model.state = !this.users || this.users.length <= 0 ? 'select' : 'display';
            }, 100);
        }
    }

    public init() {
        if (!this.service.Settings.setup) {
            return setTimeout(() => this.init(), 500);
        }
        this.model.route = this.service.Settings.get('app.style.popout');
        this.model.can_add = this.service.Settings.get('app.users.can_add');
    }

    public setState(state: string) {
        setTimeout(() => this.model.state = 'select', 300);
    }

    public closeSearch() {
        setTimeout(() => this.close.emit(), 300);
    }

    public newUser() {
        const external = this.service.Settings.get('app.booking.external_visitors');
        const search = this.service.Settings.get('app.booking.external_search');
        this.service.Overlay.openModal('user-details', { data: { backend: !external, search } })
            .then((inst: any) => inst.subscribe((event) => {
                if (event.type === 'Success') {
                    const form = event.data.form;
                    const user = form.users ? form.users[0] || form : form;
                    user.external = external;
                    user.select = true;
                    if (!this.model.user) {
                        this.model.users = [];
                    } else {
                        for (const u of this.model.users) {
                            if (u.email === user.email) {
                                return;
                            }
                        }
                    }
                    this.model.users.push(user);
                } else {
                    event.close();
                }
            }));
    }

    public confirm() {
        const list: any[] = [];
        if (this.model.users) {
            for (const item of this.model.users) {
                if (item.select) { list.push(item); }
            }
        }
        this.users = list;
        this.usersChange.emit(list);
        this.closeSearch();
    }

}
