
import { Component } from '@angular/core';
import { OverlayContentComponent } from '@acaprojects/ngx-widgets';
import { ILevel } from '../../services/data/buildings.service';

@Component({
    selector: 'level-select-model',
    templateUrl: './level-select-modal.template.html',
    styleUrls: ['./level-select-modal.styles.scss']
})
export class LevelSelectModalComponent extends OverlayContentComponent {

    public init() {
        if (this.service) {
            this.service.Buildings.listen((bld) => {
                this.model.level = { list: [] };
                if (bld && bld.levels) {
                    this.model.level.list = bld.levels;
                }
            });
        } else {
            this.fn.close();
        }
    }

    public select(lvl: ILevel) {
        this.model.active = lvl.id;
        this.fn.event('Select');
    }

}
