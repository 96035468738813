
import { Component, OnInit } from '@angular/core';

import { BaseComponent } from '../../../shared/components/base.component';
import { AppService } from '../../../services/app.service';

import * as moment from 'moment';

@Component({
    selector: 'shell-topbar',
    templateUrl: './topbar.template.html',
    styleUrls: ['./topbar.styles.scss']
})
export class ShellTopbarComponent extends BaseComponent implements OnInit {
    public model: any = {};

    constructor(private service: AppService) {
        super();
    }

    public ngOnInit() {
        super.ngOnInit();
        this.interval('time', () => this.updateTime(), 10 * 1000);
        this.updateTime();
        this.init();
    }

    public init() {
        if (!this.service.ready()) {
            return this.timeout('init', this.init());
        }
        this.model.logo = this.service.Settings.get('app.logo');
        this.model.logo_alt = this.service.Settings.get('app.logo_alt');
    }

    public updateTime() {
        this.model.time = moment().format('dddd h:mm A');
    }

    public home() {
        this.service.set('KIOSK.reset', 'force');
    }
}
