
import { Component, OnInit, OnDestroy, Input, EventEmitter, Output } from '@angular/core';
import { AppService } from '../../../services/app.service';

import * as faker from 'faker';
import * as moment from 'moment';
import { BaseComponent } from '../../../shared/components/base.component';

@Component({
    selector: 'guest-host-search',
    templateUrl: './host-search.template.html',
    styleUrls: ['./host-search.styles.scss']
})
export class  GuestHostSearchComponent extends BaseComponent implements OnInit, OnDestroy {
    @Input() public form: any = {};
    @Output() public formChange = new EventEmitter();
    @Output() public event = new EventEmitter();

    public model: any = { form: {} };

    constructor(private service: AppService) {
        super();
    }

    public ngOnInit() {
        super.ngOnInit();
        if (!this.model.form) {
            this.model.form = {};
        }
        this.init();
    }

    public ngOnDestroy() {

    }

    public init() {
        if (!this.service.Settings.setup) {
            return setTimeout(() => this.init(), 500);
        }
        this.service.Visitors.query({
            from: moment().hours(0).minutes(0).seconds(0).unix(),
            to: moment().hours(23).minutes(59).seconds(59).unix()
        }).then((list) => {
            const host_list = [];
            this.model.list = list;
            this.filter();
        }, (e) => console.log('Error:', e));
        // this.model.list = [
        //     { name: 'Jonnathan Jones', role: 'Project Manager', avatar: faker.image.avatar() },
        //     { name: 'Amanda Ryn', role: 'Sales Manager', avatar: faker.image.avatar() },
        //     { name: 'Melissa Amino', role: 'Senior CEO', avatar: faker.image.avatar() },
        //     { name: 'Joseph Smith', role: 'Lead Developer', avatar: faker.image.avatar() }
        // ];
    }

    public filter() {
        if (!this.model.search) {
            this.model.filtered_items = this.model.list;
        } else {
            this.model.filtered_items = [];
            for (const i of this.model.list) {
                if (i.name && i.name.toLowerCase().indexOf(this.model.search.toLowerCase())) {
                    this.model.filtered_items.push(i);
                }
            }
        }
    }

    public select(item: any) {
        if (item) {
            this.event.emit({ type: 'Select', item });
        }
    }
}
