
import { MapPinComponent, MapRangeComponent } from '@acaprojects/ngx-widgets';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, NavigationStart } from '@angular/router';

import { AppService } from '../../services/app.service';
import { ILevel } from '../../services/data/buildings.service';
import { RoomInfoComponent } from '../../shared/components/room-info/room-info.component';
import { BaseComponent } from '../../shared/components/base.component';

import * as moment from 'moment';
import { IRoom } from '../../services/data/rooms.service';
import { DeskInfoComponent } from '../../shared/components/desk-info/desk-info.component';

@Component({
    selector: 'app-explore',
    templateUrl: './explore.template.html',
    styleUrls: ['./explore.styles.scss']
})
export class ExploreComponent extends BaseComponent implements OnInit {
    public model: any = [];
    public deskOptions: any;

    constructor(private route: ActivatedRoute, private router: Router, private service: AppService) {
        super();
        this.model.setting_list = [
            { id: 'desks', name: 'Desks Status' },
            { id: 'rooms', name: 'Rooms Status' },
            { id: 'teams', name: 'Team Tables' },
            { id: 'zones', name: 'Zones Status' }
        ];
    }

    public ngOnInit() {
        this.deskOptions = this.service.DeskOptions;
        this.model.bind = false;
        this.timeout('bind', () => this.model.bind = true, 1000);
        this.subs.obs.router = this.router.events.subscribe((e) => {
            if (e instanceof NavigationStart) {
                this.service.set('APP.swipe_disabled', e.url.indexOf('explore') >= 0);
            } else if (e instanceof NavigationEnd) {
                if (this.model.zones) {
                    this.model.zones.show = false;
                }
                if (this.model.map) {
                    this.model.map.zoom = 100;
                    this.model.map.center = { x: .5, y: .5 };
                }
                this.clear();
                this.update();
            }
        });
        this.subs.obs.params = this.route.paramMap.subscribe((params) => {
            if (params.has('level')) {
                if (!this.model.level) { this.model.level = {}; }
                this.model.level.active = { id: params.get('level') };
                this.setLevelByID(params.get('level'));
            }
            if (params.has('search')) {
                this.model.search = params.get('search');
                this.service.set('KIOSK.search', this.model.search);
            }
        });
        this.subs.obs.clear = this.service.listen('KIOSK.has_reset', () => {
            this.model.found_item = null;
            this.model.found_user = null;
            this.updatePointsOfInterest();
        });
        this.subs.obs.query = this.route.queryParamMap.subscribe((params) => {
            if (params.has('room_id')) { this.focusOnRoom(params.get('room_id')); }
            if (params.has('level')) { this.setLevelByID(params.get('level')); }
            if (params.has('level_id')) { this.setLevelByID(params.get('level_id')); }
            if (params.has('back')) { this.model.back = params.get('back'); }
        });
        this.subs.obs.user = this.service.Users.listen('user', (item) => this.model.user = item);
        this.model.map = {};
        this.model.show = { rooms: true, desks: true };
        this.model.map.poi = [];
        this.model.desks = {};
        this.model.desks.outOfOrder = [];
        this.model.desks.occupied = [];
        this.model.desks.reserved = [];
        this.model.desks.reservations = [];
        this.model.zones = {};
        this.model.settings = {};
        this.service.set('EXPLORE.show.desks', this.model.show.desks);
        this.service.set('EXPLORE.show.rooms', this.model.show.rooms);
        this.subs.obs.exp_filter = this.service.listen('EXPLORE.filters', () => this.update());
        this.service.listen('EXPLORE.settings', (value) => this.updateSettings(value));
        this.service.listen('KIOSK.level', (value) => this.setLevelByID(value));
        this.subs.obs.exp_features = this.service.listen('EXPLORE.map.hide', (a) => {
            this.update();
        });
        this.model.colours = { rooms: {}, desks: {}, zones: {} };
        if (this.model.keys) {
            this.model.keys.show = false;
        }
        this.clear();
        this.service.set('APP.swipe_disabled', true);
        this.model.bld = null;
        this.subs.obs.building = this.service.Buildings.listen((bld) => {
            if (bld && (!this.model.bld || this.model.bld.id !== bld.id)) {
                const active = this.model.level ? this.model.level.active : null;
                this.model.level = {};
                this.model.system = bld.systems.desks;
                this.model.level.list = bld.levels;
                this.model.rooms = this.service.Rooms.list();
                this.model.room_pin = null;
                if (this.model.level.list) {
                    this.model.level.names = [];
                    for (const level of this.model.level.list) {
                        this.model.level.names.push(level.name);
                    }
                    let lvl = null;
                    if (active) { lvl = this.setLevelByID(active.id); }
                    if (!lvl && this.model.level.list.length > 0) {
                        this.setLevel(this.model.level.list[0]);
                    }
                }
                if (this.model.found_user) {
                    this.focusUser(this.model.found_user);
                }
                this.update();
                this.model.bld = bld;
            }
        });
        this.interval('time', () => this.updateTime(), 10 * 1000);
        this.updateTime();
        this.init();
    }

    public init() {
        if (!this.service.ready()) {
            this.timeout('init', () => this.init(), 500);
        }
        this.model.colours = {};
        this.model.colours.rooms = this.service.Settings.get('app.colors.rooms') || {};
        this.model.colours.desks = this.service.Settings.get('app.colors.desks') || {};
        this.model.colours.zones = this.service.Settings.get('app.colors.zones') || {};
        this.model.colours.other = this.service.Settings.get('app.colors.other') || {};
        this.model.keys = this.service.Settings.get('app.map.keys') || {};
        this.model.keys.types = Object.keys(this.model.keys);
        this.model.zones.enabled = this.service.Settings.get('app.zones.enabled');
        this.model.zones.toggle = this.service.Settings.get('app.zones.toggle');
        this.model.topbar = this.service.Settings.get('app.topbar');
        this.model.settings = this.service.Settings.get('app.explore') || {};
        if (this.model.zones.enabled && this.model.zones.toggle) {
            this.model.keys.types = [this.model.zones.toggle];
        }
        if (this.model.keys) {
            this.model.keys.list = (this.model.keys.room || []).concat(this.model.keys.general || []);
        }
        this.subs.obs.rooms = this.service.Rooms.listen('list', () => {
            this.model.rooms = this.service.Rooms.list();
            this.update();
        });
        this.model.map_bookable = this.service.Settings.get('app.map.bookings');
        this.interval('rooms', () => this.updateRoomState(), 25 * 1000);
        this.service.Analytics.screen('Explore');
        this.service.Analytics.page(`/explore${this.model.search ? '/' + this.model.search : ''}`);
        this.interval('zoom', () => this.model.map.zoom += (this.model.map.zoom > 101 ? -1 : 1), 5 * 60 * 1000);
    }

    public focusOnRoom(id: string, tries: number = 0) {
        if (tries > 10) { return; }
        if (this.model.rooms && this.model.rooms.length > 0) {
            for (const rm of this.model.rooms) {
                if (rm.id === id) {
                    this.focusSpace(rm);
                    break;
                }
            }
        } else {
            this.timeout('focus_room', () => this.focusOnRoom(id, tries++), 300 * tries++);
        }
    }

    public toggle(name: string) {
        this.timeout(`toggle_${name}`, () => this.model[name].show = !this.model[name].show, 100);
    }

    public setLevelByID(id: string, emit: boolean = true) {
        this.timeout('set_level_id', () => {
            if (id && this.model.level && this.model.level.list) {
                for (const lvl of this.model.level.list) {
                    if (lvl.id === id) {
                        this.setLevel(lvl, emit);
                        return lvl;
                    }
                }
            }
            return null;
        });
    }

    public setLevel(lvl: ILevel, emit: boolean = true, force: boolean = false) {
        this.timeout('set_level', () => {
            if (lvl && ((this.model.level && this.model.level.list && this.model.level.list.indexOf(lvl) >= 0) || force)) {
                console.warn('Level change:', lvl);
                const previous_lvl = this.model.level.active;
                this.model.level.active = lvl;
                this.model.level.index = this.model.level.list.indexOf(lvl);
                this.model.map.src = lvl.map_url;
                this.model.map.focus = { x: .5, y: .5, zoom: 100};
                this.model.room_pin = null;
                this.model.info = null;
                this.update();
                this.timeout('clear_focus', () => this.model.map.focus = null, 100);
                if (emit && (!previous_lvl || previous_lvl.id !== lvl.id)) {
                    this.service.set('KIOSK.level', lvl.id);
                }
            }
        });
    }

    public updateLevel() {
        for (const lvl of this.model.level.list) {
            if (this.model.map.src === lvl.map_url) {
                this.model.level.active = lvl;
                break;
            }
        }
        this.update();
    }

    public updateSettings(settings: any) {
        this.timeout('update_settings', () => {
            this.model.map.zoom = settings && settings.zoom !== undefined ? settings.zoom : this.model.map.zoom;
            this.model.map.center = settings && settings.center !== undefined ? settings.center : this.model.map.center;
            this.update();
            this.timeout('clear_center', () => { if (settings && settings.center) { delete settings.center; } }, 100);
        }, 20);
    }

    public updateRoomState() {
        if (this.model.rooms) {
            for (const rm of this.model.rooms) {
                this.processBookings(rm, false);
            }
            this.update();
        }
    }

    public processBookings(room: any, update: boolean = true) {
        if (room.raw_bookings && room.raw_bookings.length > 0) {
            this.service.Rooms.processRoom(room);
            if (update) { this.update(); }
            this.model.change = !this.model.change;
        }
    }

    public updateZoom() {
        const settings = this.service.get('EXPLORE.settings');
        settings.zoom = this.model.map.zoom;
        this.service.set('EXPLORE.settings', settings);
    }

    public update() {
        if (this.model.zones && this.model.show) {
            this.model.zones.show = this.model.show.zones;
        }
        if (!this.model.show) { this.model.show = {}; }
        this.model.show.desks = this.service.get('EXPLORE.show.desks');
        this.model.show.rooms = this.service.get('EXPLORE.show.rooms');
        this.model.show.teams = this.service.get('EXPLORE.show.teams');
        if (this.model.zones && this.model.zones.enabled) {
            this.model.zones.show = this.service.get('EXPLORE.show.zones');
            if (this.model.zones.toggle) {
                this.model.keys.types = [this.model.zones.show ? 'zone' : this.model.zones.toggle];
            }
        }
        this.updateListeners();
    }

    public updateState() {
        if (this.model.show) {
            this.service.set('EXPLORE.show.desks', this.model.show.desks);
            this.service.set('EXPLORE.show.rooms', this.model.show.rooms);
            this.service.set('EXPLORE.show.teams', this.model.show.teams);
        }
        if (this.model.zones) {
            this.service.set('EXPLORE.show.zones', this.model.zones.show);
        }
        this.update();
    }

    public updateListeners() {
        if (this.model.map.listeners && this.model.map.listeners.length) {
            this.model.map.listeners = [];
        }
        this.model.map.listeners = [];
        if (this.model.rooms && this.model.level && this.model.level.active) {
            for (const room of this.model.rooms) {
                if (room.map_id && room.level && room.level.id === this.model.level.active.id) {
                    if (room.bookable) {
                        const id = `area-${room.map_id}-status`;
                        const click_start = () => {
                            this.model.click_start = id;
                        };
                        const click_end = () => {
                            if (this.model.click_start === id) {
                                this.model.selected = null;
                                this.model.display = null;
                                this.service.Bookings.bookRoom(room);
                            }
                        };
                        this.model.map.listeners.push({ id, event: 'touchend', callback: click_end });
                        this.model.map.listeners.push({ id, event: 'mouseup', callback: click_end });
                        this.model.map.listeners.push({ id, event: 'mousedown', callback: click_start });
                        this.model.map.listeners.push({ id, event: 'touchstart', callback: click_start});
                    }
                }
            }
        }
        if (this.model.desks) {
            for (const deskId of (this.model.desks.reserved || [])) {
                this.addClickEventToDeskId(deskId);
            }
            for (const desk of (this.model.desks.reervations || [])) {
                this.addClickEventToDeskId(desk.desk_id);
            }
            for (const desk of (this.model.desks.occupied || [])) {
                this.addClickEventToDeskId(desk.desk_id);
            }
            for (const deskId of (this.model.desks.outOfOrder || [])) {
                this.addClickEventToDeskId(deskId);
            }
        }
        this.updateStyles();
        this.updatePointsOfInterest();
    }

    private addClickEventToDeskId (deskId: string) {
        this.model.map.listeners.push({ id: `${deskId}`, event: 'click', callback: () => this.setDesk(deskId) });
    }

    private setDesk(deskId: string) {
        this.model.desk = deskId;
    }

    public updateStyles() {
        this.timeout('styles', () => {
            this.model.style = {};
            const styles = {};
            this.model.style.general = {};
            this.updateRoomStyles();
            this.updateDeskStyles();
            this.updateZoneStyles();
            this.updateFeatureStyles();
            if (this.model.map.focus && this.model.map.focus.id) {
                const id = `.map #${this.model.map.focus.id}`;
                if (this.model.styles[id]) {
                    this.model.styles[id].opacity = 1;
                } else {
                    this.model.styles[id] = { opacity: 1 };
                }
            }
            for (const k in this.model.style) {
                if (this.model.style.hasOwnProperty(k) && this.model.style[k]) {
                    for (const i in this.model.style[k]) {
                        if (this.model.style[k].hasOwnProperty(i)) {
                            styles[i] = this.model.style[k][i];
                        }
                    }
                }
            }
            this.model.map.styles = { ...styles };
            this.clearTimer('styles');
        }, 50);
    }

    public updateRoomStyles() {
        this.model.style.rooms = {};
        if (!this.model.show.rooms) {
            this.model.style.rooms[`[id$="-status"]`] = { opacity: '0' };
            return;
        }
        if (this.model.rooms && this.model.rooms.length > 0) {
            for (const room of this.model.rooms) {
                if (this.model.level && this.model.level.active && this.model.level.active.id === room.level.id) {
                    let color = this.model.colours.rooms['not-bookable'] || '#ccc';
                    if (room.bookable && room.in_use) {
                        color = this.model.colours.rooms['unavailable'] || '#E53935';
                    } else if (room.bookable) {
                        color = this.model.colours.rooms['available'] || '#4CAF50';
                    }
                    this.model.style.rooms[`#area-${room.map_id}-status`] = {
                        fill: color,
                        opacity: '0.8',
                    };
                }
            }

        } else {
            this.timeout('room_styles', () => this.updateRoomStyles());
        }
    }

    public updateDeskStyles() {
        this.model.style.desks = {};
        const colours = this.model.colours.desks || {};
        if (!this.model.show.desks) {
            this.model.style.desks[`[id^="table-"] polygon`] = {
                fill: colours['not-bookable-fill'] || '#fff',
                stroke: colours['not-bookable-stroke'] || '#ccc'
            };
            return;
        }
        if (this.model.found_user && this.model.found_user.location && this.model.found_user.location.fixed) {
            const loc = this.model.found_user.location;
            this.model.style.desks[`#${loc.map_id} polygon`] = {
                fill: colours['unavailable-fill'] || '#fff',
                stroke: colours['unavailable-stroke'] || '#ccc'
            };
        }
        if (this.model.desks) {
            const u_desk = this.model.desks.user;
            const list = this.model.desks.in_use || [];
            const { reserved, outOfOrder, occupied, reservations } = this.model.desks;
            if (this.model.desks.list) {
                for (const label of this.model.desks.list) {
                    if (label) {
                        const desk = `${label}`;
                        const desk_obj = {
                            level: this.model.active,
                            id: desk,
                            name: `Desk ${label.split('-')[1]}`,
                        };
                        const is_reserved = reserved && reserved.indexOf(label) >= 0;
                        const in_use = list && list.indexOf(label) >= 0;
                        const users_desk = u_desk && u_desk.connected && u_desk.desk_id === label;
                        this.model.style.desks[`#${desk}`] = {
                            fill: colours['available-fill'] || '#4CAF50',
                            stroke: colours['available-stroke'] || '#4CAF50'
                        };
                        if (in_use || is_reserved) {
                            const type = users_desk ? 'user' : (is_reserved ? 'reserved' :  'unavailable');
                            this.model.style.desks[`#${desk}`].fill = colours[`${type}-fill`] || colours['unavailable-fill'] || '#fff';
                            this.model.style.desks[`#${desk}`].stroke = colours[`${type}-stroke`] || colours['unavailable-stroke'] || '#ccc';
                        }
                    }
                }
            }const reservedClass = this.deskOptions.getSelectionModeClass(this.deskOptions.RESERVED);
            const occupiedClass = this.deskOptions.getSelectionModeClass(this.deskOptions.CHECKIN);
            const outOfOrderClass = this.deskOptions.getSelectionModeClass(this.deskOptions.OUT_OF_ORDER);
            const reservationsClass = this.deskOptions.getSelectionModeClass(this.deskOptions.GROUP_RESERVATION);
            this.applyDeskStyleClasses(reserved, reservedClass, colours, true);
            this.applyDeskStyleClasses((occupied || []).map(m => m.desk_id), occupiedClass, colours);
            this.applyDeskStyleClasses((reservations || []).map(m => m.desk_id), reservationsClass, colours);
            this.applyDeskStyleClasses(outOfOrder, outOfOrderClass, colours);
        }
    }

    private applyDeskStyleClasses(deskIds: string[], className: string, colours: any, reserved: boolean = false) {
        if (deskIds && deskIds.length && className && className.length) {
            for (const deskId of deskIds) {
                this.model.style.desks[`#${deskId} polygon`] = {
                    fill: colours[`${className}-fill`],
                    stroke: colours[`${className}-stroke`]
                };
                this.model.style.desks[`#${deskId} path`] = {
                    display: reserved ? 'initial' : 'none',
                };
            }
        }
    }

    public updateZoneStyles() {
        if (!this.model.level) { return this.timeout('zone_styles', () => this.updateZoneStyles()); }
        this.model.style.zones = {};
        const colors = this.model.colours.zones || {};
        const lvl = this.model.level.active;
        // if (!this.model.zones || !this.model.zones.enabled || !this.model.zones.show) {
        //     this.model.style.zones['#Zones > *'] = { opacity: '0' };
        // }
        if (this.model.colours && this.model.level && lvl) {
            if (!lvl.defaults) { lvl.defaults = {}; }
            this.model.style.zones['[id^="zone"]'] = {
                fill: colors[lvl.defaults.zone] || colors.default || '#317c36'
            };
        }
        if (this.model.zone_usage) {
            for (const zone in this.model.zone_usage) {
                if (this.model.zone_usage.hasOwnProperty(zone)) {
                    const min_percent = lvl.defaults.zone_min || 0;
                    const zone_usage = (this.model.zone_usage[zone].people_count / this.model.zone_usage[zone].capacity) * 100;
                    const percent = Math.max(min_percent, zone_usage);

                    const fill = percent < 40 ? colors.low || '#317c36' : (percent < 75 ? colors.medium || '#f4a81d' : colors.high || '#cd212e');
                    this.model.map.styles[`#${zone}`] = { fill };
                    this.model.map.styles[`#${zone}-status`] = { fill };
                }
            }
        }
    }

    public updateFeatureStyles() {
        this.model.style.features = {};
        const hidden_features = this.service.get('EXPLORE.map.hide') || [];
        for (const id of hidden_features) {
            this.model.style.features[`#${id}`] = { opacity: '0' };
        }
    }

    public check(e: any) {
        if (e.type === 'warning' && this.model.found_user && this.model.found_user.location &&
                this.model.found_user.location.map_id && e.msg.indexOf(this.model.found_user.location.map_id) >= 0) {
            this.model.found_user.pin_error = true;
        }
    }

    public focus(item: any) {
        this.model.map.center = { x: .5, y: .5 };
        if (item.type) {
            this.focusUser(item);
        } else {
            this.focusSpace(item);
        }
    }

    public focusUser(user: any) {
        if (user) {
            this.model.found_item = null;
            this.model.focus_room = null;
            if (user.type === 'role') {
                this.updatePointsOfInterest();
                this.service.Users.show(user.email).then((u) => {
                    this.locate(u);
                }, (err) => null);
            } else {
                this.locate(user);
            }
        }
    }

    public locate(user) {
        if (!user) { return; }
        user.location = null;
        this.model.found_user = user;
        this.service.Users.location(user.id, user.win_id).then((location) => {
            if (!this.model.found_user) { return; }
            this.model.found_user.location = location;
            let found = false;
            const bld = this.service.Buildings.current();
                // Cross building location finding is enabled
            if (this.model.settings.cblf) {
                const level: ILevel = this.service.Buildings.getLevel(location.level);
                if (level) {
                    for (const lvl of (bld ? bld.levels : this.model.level.list)) {
                        if (level.id === lvl.id) {
                            this.setLevel(lvl);
                            found = true;
                            break;
                        }
                    }
                        // Level is in another building
                    if (!found) {
                        this.setLevel(level, false, true);
                        this.model.found_user.location.building = this.service.Buildings.get(level.bld_id);
                        this.model.found_user.external = true;
                        found = true;
                    }
                }
            } else { // Only locate users in the current building
                for (const lvl of (bld ? bld.levels : this.model.level.list)) {
                    if (location.level === lvl.id) {
                        this.setLevel(lvl);
                        found = true;
                        break;
                    }
                }
            }
            if (found) {
                this.timeout('focus', () => {
                this.model.map.focus = {
                    coordinates: !location.fixed ? { x: location.x, y: location.y } : null,
                    id: location.map_id,
                    zoom: 150,
                };
                }, 200);
            } else {
                this.model.found_user.location = null;
                this.model.found_user.external = true;
            }
            this.updatePointsOfInterest();
        }, (err) => {
            this.model.found_user.location = null;
            this.updatePointsOfInterest();
        });
    }

    public focusSpace(item: any) {
        if (!this.model || !this.model.level || !this.model.level.list || !item) {
            return;
        }
        const focus = `${item.prefix === false ? '' : 'area-'}${item.map_id}${item.bookable ? '-status' : ''}`;
        const lvl = item.level;
        if (lvl) {
            for (const level of this.model.level.list) {
                if (lvl.id === level.id) {
                    this.setLevel(level);
                    this.timeout('focus_space', () => this.model.map.focus = { id: focus, zoom: 150 }, 200);
                    break;
                }
            }
            this.timeout('update_space', () => {
                this.model.found_user = null;
                this.model.focus_room = item;
                this.updatePointsOfInterest();
            }, 20);
        }
    }

    public clear() {
        this.model.found_item = null;
        this.model.focus_room = null;
        this.model.found_user = null;
        this.model.info = null;
        this.updatePointsOfInterest();
    }

    public updatePointsOfInterest() {
        this.timeout('poi', () => {
            this.model.map.poi = [];
            this.model.toggle = !this.model.toggle;
            if (this.model.found_user && this.model.found_user.location && this.model.found_user.location.level) {
                if (this.model.found_user.location.level === this.model.level.active.id) {
                    const loc = this.model.found_user.location;
                    this.model.map.poi.push({
                        id: loc.map_id || `person-${this.model.found_user.id}`,
                        content: loc.fixed ? MapPinComponent : MapRangeComponent,
                        coordinates: !loc.fixed ? { x: loc.x, y: loc.y } : null,
                        data: {
                            text: `${this.model.found_user.name} is here`,
                            back: '#F44336',
                            diameter: (loc.confidence || 1) / 1
                        }
                    });
                }
            }
            if (this.model.info) {
                const room: IRoom = this.model.info;
                const today = moment();
                const bookings = room.bookings.filter(b => moment(b.date).isSame(today, 'd'));
                const block = this.service.Bookings.getNextFreeBlock(bookings);
                if (room.current && block && block.start !== -1) {
                    this.model.selected_time = `Booked until ${moment(block.start).format('h:mmA')}`;
                } else if (room.current) {
                    const end = moment(room.current.date).add(room.current.duration, 'm');
                    this.model.selected_time = `Booked ${end.isAfter(today, 'd') ? 'today' : 'until ' + room.current.display.end}`;
                } else if (block && block.end >= 0) {
                    const end = block.end < 0 ? moment().hours(23).minutes(59) : moment(block.end);
                    const dur = Math.floor(moment.duration(end.diff(today)).asMinutes());
                    this.model.selected_time = `Free until ${end.format('h:mmA')}`;
                    if (dur < 30) {
                        if (block.end < 0) {
                            this.model.selected_time += `<br>Next available at tomorrow`;
                        } else {
                            const next_blk = this.service.Bookings.getNextFreeBlock(bookings, 30, block.end);
                            this.model.selected_time += `<br>Next available at ${moment(next_blk.start).format('h:mmA')}`;
                        }
                    }
                } else if (room.next && block) {
                    this.model.selected_time = `Free until ${room.next.display.start}`;
                    if (block.start > 0) {
                        this.model.selected_time += `<br>Next available at ${moment(block.start).format('h:mmA')}`;
                    }
                } else {
                    this.model.selected_time = `Free today`;
                }
                this.model.display = null;
                this.model.map.poi.push({
                    id: `area-${room.map_id}-status`,
                    content: RoomInfoComponent,
                    data: {
                        room,
                        display: { time: this.model.selected_time },
                        available: !room.current
                    }
                });
            }
            if (this.model.focus_room && this.model.focus_room.level.id === this.model.level.active.id) {
                const rm = this.model.focus_room;
                this.model.map.poi.push({
                    id: `${rm.prefix === false ? '' : 'area-'}${rm.map_id}${rm.bookable ? '-status' : ''}`,
                    prefix: 'pin',
                    content: MapPinComponent,
                    data: {
                        back: this.model.colours.rooms.pin || '#03A9F4',
                        text: this.model.focus_room.name
                    }
                });
            }
            if (this.model.desk) {
                const { reservations } = this.model.desks;
                this.model.map.poi.push({
                    id: this.model.desk,
                    content: DeskInfoComponent,
                    data: {
                        system: this.model.system,
                        desk_id: this.model.desk,
                        reservation: reservations.find(r => r.desk_id === this.model.desk)
                    }
                });
            }
        }, 300);
    }

    public back() {
        if (this.model.back) {
            this.service.navigate(this.model.back);
        } else {
            this.service.back();
        }
    }

    public updateTime() {
        this.model.time = moment().format('h:mm A');
    }

}
