import { OverlayContentComponent } from '@acaprojects/ngx-widgets';
import { Component } from '@angular/core';

import * as moment from 'moment';

@Component({
    selector: 'visitor-modal',
    templateUrl: './visitor-modal.template.html',
    styleUrls: ['./visitor-modal.styles.scss'],
})
export class VisitorModalComponent extends OverlayContentComponent {
    public model: any = {};

    public init() {
        this.model.focus = {};
        this.model.form = JSON.parse(JSON.stringify(this.model.visitor || {}));
        if (typeof this.model.form.organisation === 'object') {
            this.model.form.organisation = this.model.form.organisation.name;
        }
    }

    public close() {
        setTimeout(() => this.fn.close(), 300);
    }

    public event(name: string) {
        setTimeout(() => this.fn.event(name), 300);
    }
}
