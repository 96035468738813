
import { AppShellComponent } from './shell.component';
import { HelpComponent } from './help/help.component';
import { ShellSidebarComponent } from './components/sidebar/sidebar.component';
import { ShellTopbarComponent } from './components/topbar/topbar.component';
import { HomeComponent } from './home/home.component';
import { GUEST_CHECKIN_COMPONENTS } from './guest';
import { WelcomeComponent } from './welcome/welcome.component';

export const APP_COMPONENTS: any[] = [
    AppShellComponent,
    HelpComponent,
    HomeComponent,
    ShellSidebarComponent,
    ShellTopbarComponent,
    WelcomeComponent,
    ...GUEST_CHECKIN_COMPONENTS
];
