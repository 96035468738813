
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';

import { ComposerModule } from '@acaprojects/ngx-composer';
import { WidgetsModule } from '@acaprojects/ngx-widgets';

import { AppComponent } from './app.component';
import { BootstrapComponent } from './bootstrap/bootstrap.component';
import { APP_COMPONENTS } from './shell';
import { ROUTES } from './app.routes';
import { environment } from '../environments/environment';

import './shared/mock';

import { SharedComponentModule } from './shared/components/shared.module';
import { OverlayComponentModule } from './overlays/overlays.module';

import { AppExploreModule } from './shell/explore/explore.module';

@NgModule({
    declarations: [
        AppComponent,
        BootstrapComponent,
        ...APP_COMPONENTS
    ],
    imports: [
        BrowserAnimationsModule,
        BrowserModule,
        RouterModule.forRoot(ROUTES, { useHash: true }),
        HttpClientModule,
        FormsModule,
        ServiceWorkerModule.register('/kiosk/ngsw-worker.js', { enabled: environment.production }),
        WidgetsModule.forRoot(),
        ComposerModule,
        AppExploreModule,
        SharedComponentModule,
        OverlayComponentModule
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
