
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AppService } from '../../services/app.service';
import { BaseComponent } from '../../shared/components/base.component';

@Component({
    selector: 'app-help',
    templateUrl: './help.template.html',
    styleUrls: ['./help.styles.scss']
})
export class HelpComponent extends BaseComponent implements OnInit {
    public model: any = {};

    private timers: any = {};

    constructor(private route: ActivatedRoute, private service: AppService) {
        super();
        this.route.paramMap.subscribe((params) => {
            if (params.has('level')) {
                this.service.set('KIOSK.level', params.get('level'));
                this.model.level = params.get('level');
            }
        });
    }

    public ngOnInit() {
        super.ngOnInit();
        this.model.settings = {};
        this.init();
    }

    public init() {
        if (!this.service.Settings.setup) {
            return setTimeout(() => this.init(), 300);
        }
        this.model.tiles = this.service.Settings.get('app.help.tiles') || [];
        this.model.type = this.service.Settings.get('app.help.type');
        this.model.disclaimer = this.service.Settings.get('app.help.disclaimer');
        this.model.settings = this.service.Settings.get('app.help') || {};
    }

    public goto(item: any) {
        if (item.id) {
            this.service.navigate(['explore', item.id, this.model.level], item.query || {});
        } else if (item.link || item.url) {
            this.model.link = item.link || item.url;
        }
    }

}
