import { Injectable } from '@angular/core';
import { CommsService } from '@acaprojects/ngx-composer';
import { BehaviorSubject } from 'rxjs';

import { BaseService } from './base.service';

@Injectable({
    providedIn: 'root'
})
export class QRCodeService extends BaseService {

    constructor(protected http: CommsService) {
        super();
        this.model.name = 'qrcode';
        this.model.route = '/qr';
        this.subjects.list = new BehaviorSubject<any[]>([]);
        this.observers.list = this.subjects.list.asObservable();
    }

    /**
     * Convert item data to local format
     * @param item QR Code data
     */
    protected processItem(item: any) {
        return this.parent.Users.processItem(item);
    }

}
