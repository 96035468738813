import { OverlayContentComponent } from '@acaprojects/ngx-widgets';
import { Component } from '@angular/core';

import * as moment from 'moment';
import { IUser } from '../../services/data/users.service';

@Component({
    selector: 'new-visitor-modal',
    templateUrl: './new-visitor-modal.template.html',
    styleUrls: ['./new-visitor-modal.styles.scss'],
})
export class NewVisitorModalComponent extends OverlayContentComponent {
    public model: any = {};

    private timers: any = {};

    public init() {
        this.model.focus = {};
        this.model.form = JSON.parse(JSON.stringify(this.model.visitor || {}));
        if (typeof this.model.form.organisation === 'object') {
            this.model.form.organisation = this.model.form.organisation.name;
        }
    }

    public close() {
        setTimeout(() => this.fn.close(), 300);
    }

    public event(name: string) {
        if (this.model.form && this.model.form.email) {
            this.model.form.email = this.model.form.email.toLowerCase();
        }
        setTimeout(() => this.fn.event(name), 300);
    }

    public checkHost() {
        this.model.form.host = null;
        if (this.model.form && this.model.form.host_name && !this.model.checking_email) {
            this.timeout('check-email', () => {
                this.model.checking_email = true;
                    this.service.Users.query({ q: this.model.form.host_name }).then((list) => {
                        if (list && list.length === 1) {
                            this.service.success(`Found host matching '${this.model.form.host_name}'`);
                            this.setUser(list[0]);
                        }
                        this.model.checking_email = false;
                    }, (e) => this.model.checking_email = false);
            }, 500);
        }
    }

    public checkEmail() {
        this.model.valid_email = false;
        this.model.has_error = false;
        const regex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
        if (!this.model.form && !this.model.form.email) {
            return;
        } else if (regex.test(this.model.form.email.toLowerCase())) {
            this.model.valid_email = true;
        } else {
            this.model.has_error = true;
        }
    }

    public timeout(name: string, fn: () => void, delay: number = 300) {
        this.clearTimer(name);
        if (!(fn instanceof Function)) { return; }
        this.timers[name] = setTimeout(() => fn(), delay);
    }

    public clearTimer(name: string) {
        if (this.timers[name]) {
            clearTimeout(this.timers[name]);
            this.timers[name] = null;
        }
    }

    private setUser(user: IUser) {
        if (user) {
            this.model.form.host = user;
            this.model.form.host_name = user.name;
        }
    }
}
