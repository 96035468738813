import { Component, EventEmitter, Input, Output } from '@angular/core';

import * as moment from 'moment';

@Component({
    selector: 'user-tag',
    templateUrl: './user-tag.template.html',
    styleUrls: ['./user-tag.styles.scss'],
})
export class UserTagComponent {
    @Input() public name: string = '';
    @Input() public job: boolean = false;
    @Input() public email: boolean = false;
    @Input() public details: boolean = true;
    @Input() public model: any = { action: false };
    @Output() public tapped: any = new EventEmitter();
    @Output() public action: any = new EventEmitter();

    public state: any = {};
    public display: any = {};

    public ngOnChanges(changes) {
    }

    public tap() {
        setTimeout(() => {
            if (this.state.action) { return; }
            this.tapped.emit();
        }, 10);
    }

    public actionEvent() {
        this.state.action = true;
        this.action.emit();
        setTimeout(() => {
            this.state.action = false;
        }, 30);
    }
}
